import { ZeissIdConfig } from '@planbgmbh/zeiss-id-angular-library';
import { environment } from '@environments/environment';

/**
 * Config from the @planbgmbh/zeiss-id-angular-library
 */
export const ZEISSID_CONFIG: ZeissIdConfig = {
  discoveryDocumentUrl: environment.zeissIdConfig.discoveryDocumentUrl,
  redirectUri: `${window.location.origin}`,
  clientId: environment.zeissIdConfig.clientId,
  issuer: environment.zeissIdConfig.issuer,
  responseType: 'code',
  requestAccessToken: false,
  strictDiscoveryDocumentValidation: false,
  clearHashAfterLogin: true,
  // If the client id is addod to the scopes, the B2C is returning an access_token
  // With this access_token the library can automatically detect the timers for the automatic refresh.
  // It seems like this is the best option to get as near as possible to the standard
  scope: `openid offline_access profile ${environment.zeissIdConfig.clientId}`,
  showDebugInformation: environment.zeissIdConfig.debug,
  enableLogs: environment.zeissIdConfig.debug,
  postLogoutRedirectUri: `${window.location.origin}/home`,
  timeoutFactor: 0.75,
  customQueryParams: {
    response_mode: <'query' | 'fragment' | 'form_post'>'query',
  },
  interceptorConfiguration: {
    addAsBearer: true,
    whitelistUrls: [environment.apiBaseUrl],
  },
  // Override the default fallback access token expiration time
  // In the Zeiss ID case this config is also overwriting the refresh token expiration time
  // This fix is required because Zeiss ID is not returning an expiration time for the refresh token
  //
  // For Debug mode the fallback access token expiration time is 60 seconds
  // Otherwise the fallback access token expiration time is 24 hours (60 * 60 * 24)
  fallbackAccessTokenExpirationTimeInSec: environment.zeissIdConfig.debug ? 60 : 60 * 60 * 24,
};