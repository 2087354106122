<ng-container>
  <img alt="close button" class="cursor-pointer w-4" (click)="openDialog()" src="assets/icons/close.svg">

  <div *ngIf="dialogOpen" class="fixed top-0 bottom-0 left-0 right-0 z-50 backdrop-brightness-50">
    <zui-dialogbox
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-96"
      [confirmPosition]="'right'"
      [headerText]="'cancel.title'|translate" closable="true"
      (zui-dialog-box-accepted)="closeConfig()"
      (zui-dialog-box-cancelled)="closeDialog()"
    >

      <div class="absolute top-0 bottom-0 right-0 left-0 z-10 pt-5 pb-5">
        <div class="flex flex-col">
          <zui-icon-alerts-ewi-question class="self-center" size="s32"></zui-icon-alerts-ewi-question>
          <p class="self-center text-center pt-5">
            {{ 'cancel.quit' | translate }}
          </p>
        </div>
      </div>
    </zui-dialogbox>
  </div>
</ng-container>
