import {Component, EventEmitter, Input, Output} from '@angular/core';
import { IndicatorItem } from '@typings/step-indicator-item.type';
import { IndicatorSteps } from '@typings/steps.type';

@Component({
  selector: 'app-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.css']
})
export class StepIndicatorComponent {
  
  @Input() public current!: IndicatorSteps;

  @Input() public steps!: Array<IndicatorItem>;

  @Output() public interact: EventEmitter<{target: IndicatorSteps}> = new EventEmitter();
  
  constructor() {}
}
