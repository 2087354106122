export const selectedLanguage = localStorage.getItem('ttl_language');

/**
 * Save the language code to the local storage
 * @param language language code to save
 * @example
 * saveLanguage('en-US');
 */
export const saveLanguage = (language: string): void => {
    localStorage.setItem('ttl_language', language);
}