import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Refraction} from "@typings/measurement.type";
import {Router} from "@angular/router";
import {ApiService} from "@services/api.service";
import { stepFactory } from '@configs/step-indicator.config';
import { TranslateService } from '@ngx-translate/core';
import { IndicatorSteps } from '@typings/steps.type';
import { Logger } from '@utils/logging.utils';
import { TTLValidator } from 'src/app/validators/ttl.validator';
import { console } from '@utils/rxjs.utils';
import { LogLevel } from '@typings/log-level.enum';
import { isNullOrEmtpy, parseLocalFloat } from '@utils/validation.utils';

@Component({
  selector: 'app-refraction',
  templateUrl: './refraction.component.html',
  styleUrls: ['./refraction.component.css']
})
export class RefractionComponent implements OnInit{

  public steps = stepFactory(this._translate);

  public refractionForm = new FormGroup(({
    carrierSphereR: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 0.25, min: -10, max: 6, optional: true})
    ]),
    carrierCylinderR: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 0.25, min: -4.00, max: 4.00, optional: true})
    ]),
    carrierAxisR: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 1, min: 0, max: 180, optional: true})
    ]),

    carrierSphereL: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 0.25, min: -10, max: 6, optional: true})
    ]),
    carrierCylinderL: new FormControl<number | null | string>(null, [
      TTLValidator({steps: 0.25, min: -4.00, max: 4.00, optional: true})
    ]),
    carrierAxisL: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 1, min: 0, max: 180, optional: true})
    ]),


    ocularSphereR: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 0.25, min: -10, max: 6, optional: true})
    ]),
    ocularCylinderR: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 0.25, min: -4.00, max: 4.00, optional: true})
    ]),
    ocularAxisR: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 1, min: 0, max: 180, optional: true})
    ]),

    ocularSphereL: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 0.25, min: -10, max: 6, optional: true})
    ]),
    ocularCylinderL: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 0.25, min: -4.00, max: 4.00, optional: true})
    ]),
    ocularAxisL: new FormControl<number | null | string>(null, [
      // Validators.required,
      TTLValidator({steps: 1, min: 0, max: 180, optional: true})
    ]),
  }))

  private _skipFlag: boolean = false;

  private _refractionObject: Refraction = {
    carrierLense: {
    sphere: {
      right: 0,
      left: 0
    },
    cylinder: {
      right: 0,
      left: 0
    },
    axis: {
      right: 0,
      left: 0
    }
  },
    ophthalmicLense: {
      sphere: {
        right: 0,
        left: 0
      },
      cylinder: {
        right: 0,
        left: 0
      },
      axis: {
        right: 0,
        left: 0
      }
    }
  }

  public get skipFlag() {
    return this._skipFlag;
  }

  public set skipFlag(value: boolean) {
    this._skipFlag = value;
    if (value){
      this.refractionForm.disable();
    } else {
      this.refractionForm.enable();
    }
  }

  public validateMinus(value?: number | null | string): boolean {
    if(value === null || value === undefined) return false;
    return parseLocalFloat(value!.toString()) < 0;
  }

  public get refractionValidation() {
    const anyCarrier = !isNullOrEmtpy(this.refractionForm.controls.carrierSphereR.value?.toString()) || !isNullOrEmtpy(this.refractionForm.controls.carrierSphereL.value?.toString());
    const carrier = !isNullOrEmtpy(this.refractionForm.controls.carrierSphereR.value?.toString()) && !isNullOrEmtpy(this.refractionForm.controls.carrierSphereL.value?.toString());
    
    const anyOcular = !isNullOrEmtpy(this.refractionForm.controls.ocularSphereR.value?.toString()) || !isNullOrEmtpy(this.refractionForm.controls.ocularSphereL.value?.toString());
    const ocular = !isNullOrEmtpy(this.refractionForm.controls.ocularSphereR.value?.toString()) && !isNullOrEmtpy(this.refractionForm.controls.ocularSphereL.value?.toString());


    let anyValid = true;

    let anyFilled = false;

    for(let key in this.refractionForm.controls) {
      const field = (this.refractionForm.controls as any)[key] as FormControl;
      if(!isNullOrEmtpy(field.value?.toString())) {
        anyFilled = true;
        break;
      }
    }

    if(anyCarrier) {
      anyValid = anyValid && carrier;
    }

    if(anyOcular) {
      anyValid = anyValid && ocular;
    }

    const isMinusCylinder = 
      this.validateMinus(this.refractionForm.controls.carrierCylinderL.value) || 
      this.validateMinus(this.refractionForm.controls.carrierCylinderR.value) || 
      this.validateMinus(this.refractionForm.controls.ocularCylinderL.value) || 
      this.validateMinus(this.refractionForm.controls.ocularCylinderR.value);

    return !isMinusCylinder && anyFilled && anyValid && this.refractionForm.valid || this.refractionForm.disabled;
  }

  constructor(
    private readonly _router: Router, 
    private readonly _apiService: ApiService,
    private readonly _translate: TranslateService
  ) {}

  ngOnInit() {
    this.skipFlag = this._apiService.measurementFactory.getRefraction()?.skiped || false;

    this.refractionForm.valueChanges.pipe(console('Value Change: ', LogLevel.Debug)).subscribe(_ => Logger.debug('Value State: ', this.refractionForm));
    
    this.refractionForm.patchValue({
      carrierSphereR: this._apiService.measurementFactory.getRefraction()?.carrierLense?.sphere.right,
      carrierCylinderR: this._apiService.measurementFactory.getRefraction()?.carrierLense?.cylinder.right,
      carrierAxisR: this._apiService.measurementFactory.getRefraction()?.carrierLense?.axis.right,
      carrierSphereL: this._apiService.measurementFactory.getRefraction()?.carrierLense?.sphere.left,
      carrierCylinderL: this._apiService.measurementFactory.getRefraction()?.carrierLense?.cylinder.left,
      carrierAxisL: this._apiService.measurementFactory.getRefraction()?.carrierLense?.axis.left,

      ocularSphereR: this._apiService.measurementFactory.getRefraction()?.ophthalmicLense?.sphere.right,
      ocularCylinderR: this._apiService.measurementFactory.getRefraction()?.ophthalmicLense?.cylinder.right,
      ocularAxisR: this._apiService.measurementFactory.getRefraction()?.ophthalmicLense?.axis.right,
      ocularSphereL: this._apiService.measurementFactory.getRefraction()?.ophthalmicLense?.sphere.left,
      ocularCylinderL: this._apiService.measurementFactory.getRefraction()?.ophthalmicLense?.cylinder.left,
      ocularAxisL: this._apiService.measurementFactory.getRefraction()?.ophthalmicLense?.axis.left,
    })
  }

  public async interactWithStepIndicator(event: {target: IndicatorSteps}) {
    const current = this.steps.findIndex(v => v.value === 'refraction');
    const target = this.steps.findIndex(v => v.value === event.target);
    
    const msg = `Step Indicator Interaction for ${event.target} is ${current >= target || (current < target && this.refractionForm.valid) ? 'valid' : 'invalid'}`;
    Logger.debug(msg);
    
    if (current >= target || (current < target && this.refractionForm.valid)){
      this.save();
      await this._router.navigate([event.target]);
    }
  }

  // If cylinder is negative
  // A(+) = A(-) + B(-)
  // B(+) = B(-) * -1
  // C(+) = C(-) + 90 % 180 

  // If cylinder is positive
  // A(-) = A(+) - B(+)
  // B(-) = B(+) * -1
  // C(-) = C(+) + 90 % 180
  public cylinderCalculation() {

    //
    // Carrier
    //

    if(!isNullOrEmtpy(this.refractionForm.controls.carrierCylinderL.value?.toString())) {
      const sphere = parseLocalFloat(this.refractionForm.controls.carrierSphereL.value!.toString()) + parseLocalFloat(this.refractionForm.controls.carrierCylinderL.value!.toString());
      const cylinder = parseLocalFloat(this.refractionForm.controls.carrierCylinderL.value!.toString()) * -1;
      const axis = (parseLocalFloat(this.refractionForm.controls.carrierAxisL.value!.toString()) + 90) % 180;

      this.refractionForm.controls.carrierSphereL.setValue(sphere.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      this.refractionForm.controls.carrierCylinderL.setValue(cylinder.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      this.refractionForm.controls.carrierAxisL.setValue(axis.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
    }

    if(!isNullOrEmtpy(this.refractionForm.controls.carrierCylinderR.value?.toString())) {
      const sphere = parseLocalFloat(this.refractionForm.controls.carrierSphereR.value!.toString()) + parseLocalFloat(this.refractionForm.controls.carrierCylinderR.value!.toString());
      const cylinder = parseLocalFloat(this.refractionForm.controls.carrierCylinderR.value!.toString()) * -1;
      const axis = (parseLocalFloat(this.refractionForm.controls.carrierAxisR.value!.toString()) + 90) % 180;

      this.refractionForm.controls.carrierSphereR.setValue(sphere.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      this.refractionForm.controls.carrierCylinderR.setValue(cylinder.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      this.refractionForm.controls.carrierAxisR.setValue(axis.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
    }

    //
    // Ocular
    //

    if(!isNullOrEmtpy(this.refractionForm.controls.ocularCylinderL.value?.toString())) {
      const sphere = parseLocalFloat(this.refractionForm.controls.ocularSphereL.value!.toString()) + parseLocalFloat(this.refractionForm.controls.ocularCylinderL.value!.toString());
      const cylinder = parseLocalFloat(this.refractionForm.controls.ocularCylinderL.value!.toString()) * -1;
      const axis = (parseLocalFloat(this.refractionForm.controls.ocularAxisL.value!.toString()) + 90) % 180;

      this.refractionForm.controls.ocularSphereL.setValue(sphere.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      this.refractionForm.controls.ocularCylinderL.setValue(cylinder.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      this.refractionForm.controls.ocularAxisL.setValue(axis.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
    }

    if(!isNullOrEmtpy(this.refractionForm.controls.ocularCylinderR.value?.toString())) {
      const sphere = parseLocalFloat(this.refractionForm.controls.ocularSphereR.value!.toString()) + parseLocalFloat(this.refractionForm.controls.ocularCylinderR.value!.toString());
      const cylinder = parseLocalFloat(this.refractionForm.controls.ocularCylinderR.value!.toString()) * -1;
      const axis = (parseLocalFloat(this.refractionForm.controls.ocularAxisR.value!.toString()) + 90) % 180;

      this.refractionForm.controls.ocularSphereR.setValue(sphere.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      this.refractionForm.controls.ocularCylinderR.setValue(cylinder.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      this.refractionForm.controls.ocularAxisR.setValue(axis.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}));
    }
  }


  private save() {
    this._refractionObject.carrierLense!.sphere = {
      right: parseLocalFloat(this.refractionForm.controls.carrierSphereR.value?.toString() ?? "0")|| 0,
      left: parseLocalFloat(this.refractionForm.controls.carrierSphereL.value?.toString() ?? "0")|| 0
    }
    
    this._refractionObject.carrierLense!.cylinder = {
      right: parseLocalFloat(this.refractionForm.controls.carrierCylinderR.value?.toString() ?? "0")|| 0,
      left: parseLocalFloat(this.refractionForm.controls.carrierCylinderL.value?.toString() ?? "0")|| 0
    }

    this._refractionObject.carrierLense!.axis = {
      right: parseLocalFloat(this.refractionForm.controls.carrierAxisR.value ?.toString() ?? "0")|| 0,
      left: parseLocalFloat(this.refractionForm.controls.carrierAxisL.value ?.toString() ?? "0")|| 0
    }

    this._refractionObject.ophthalmicLense!.sphere = {
      right: parseLocalFloat(this.refractionForm.controls.ocularSphereR.value?.toString() ?? "0")||0,
      left: parseLocalFloat(this.refractionForm.controls.ocularSphereL.value?.toString() ?? "0")||0
    }

    this._refractionObject.ophthalmicLense!.cylinder = {
      right: parseLocalFloat(this.refractionForm.controls.ocularCylinderR.value?.toString() ?? "0")||0,
      left: parseLocalFloat(this.refractionForm.controls.ocularCylinderL.value?.toString() ?? "0")||0
    }

    this._refractionObject.ophthalmicLense!.axis = {
      right: parseLocalFloat(this.refractionForm.controls.ocularAxisR.value?.toString() ?? "0")||0,
      left: parseLocalFloat(this.refractionForm.controls.ocularAxisL.value?.toString() ?? "0")||0
    }

    this._apiService.measurementFactory.addRefraction({skiped: this._skipFlag})

    if(!this._skipFlag) {
      this._apiService.measurementFactory.addRefraction(this._refractionObject);
    }
  }

  public nextPage(): void {
    this.save();
    this._router.navigate(['/summary'])
  }

  public previousPage(): void {
    this._router.navigate(['/centration'])
  }
}
