import { ItemSyncState } from "@typings/item-sync-state";
import { Logger } from "./logging.utils";

/**
 * Built-In cache for the application
 * @param T Type of the cache
 * 
 * @example
 * const cache = new ApplicationCache<string>();
 * cache.add('test');
 * cache.save();
 */
export class ApplicationCache<T> {

  /**
   * Internal representation of the cache
   */
  private _cache: (T & {state: ItemSyncState})[] = [];

  /**
   * Key to use for the cache in localStorage
   */
  private readonly _cacheKey: string;

  /**
   * Creates a new ApplicationCache and loads the cache from localStorage if it exists
   * @param key Key to use for the cache in localStorage
   */
  constructor(key: string = 'application') {
    this._cacheKey = `${key}-cache`;
    
    this.load();
  }

  /**
   * List of items in the cache
   */
  public get cache(): (T & {state: ItemSyncState})[] {
    return this._cache;
  }

  /**
   * Number of items in the cache
   */
  public get length(): number {
    return this._cache.length;
  }

  /**
   * Adds an item to the cache
   * Automatically saves the cache to localStorage
   * @param item Item to add to the cache
   */
  public add(item: T): void {
    this._cache.push({...item, state: 'cached'});
    this.save();
  }

  /**
   * Removes an item from the cache
   * Automatically saves the cache to localStorage
   * @param item Item to remove from the cache
   */
  public remove(item: T): void {
    this._cache = this._cache.filter((i) => i !== item);
    this.save();
  }

  /**
   * Removes the last item from the cache and returns it
   * Automatically saves the cache to localStorage
   * @returns the last item from the cache
   */
  public pop(): T | undefined {
    const item = this._cache.pop();
    this.save();
    return item;
  }

  /**
   * Removes the first item from the cache and returns it
   * @returns the first item from the cache
   */
  public shift(): T | undefined {
    const item = this._cache.shift();
    this.save();
    return item;
  }

  /**
   * Remove all items from the cache
   */
  public clear(): void {
    this._cache = [];
    this.save();
  }

  /**
   * Saves the cache to localStorage
   * Automatically called when adding or removing items from the cache
   */
  public save(): void {
    localStorage.setItem(this._cacheKey, JSON.stringify(this._cache));
    Logger.debug(`[Cache] Saved ${this._cache.length} items to local storage`);
  }

  /**
   * Loads the cache from localStorage if it exists
   */
  public load(): void {
    Logger.debug(`[Cache] Loading ${this._cacheKey} from storage...`);
    const cache = localStorage.getItem(this._cacheKey);
    if (cache) {
      this._cache = JSON.parse(cache);
      Logger.debug(`[Cache] Loaded ${this._cache.length} items from local storage`);
    }
  }
}
