<ng-container>
  <zui-step-indicator-bar  class=" bg-step-indicator-background" size="l" [value]="current">
    <zui-step-indicator-item 
      *ngFor="let step of steps; index as i" 
      [ngClass]="{
        'text-white': step.value === current, 
        'text-zeiss-grey': step.value !== current,
        'hover:cursor-pointer': step.value | previous : steps : current
      }" [text]="(i+1) + ' ' + step.text" 
      [value]="step.value"
      (click)="interact.emit({target: step.value})"
    ></zui-step-indicator-item>
  </zui-step-indicator-bar>
</ng-container>
