<ng-container>
  <div class="hidden fixed top-0 left-0 bottom-0 right-0 bg-white z-10 items-center" [ngClass]="{'browser:flex': !disableInstallationScreen}">
    <div class="w-full flex justify-center gap-8">
      <!-- Icon -->
      <div class="">
        <zui-zeiss-logo size="s80"></zui-zeiss-logo>
      </div>

      <!-- Text -->
      <div class="flex flex-col gap-12 max-w-2xl">
        <div class="w-full text-[#32373E]">
          <p class="text-lg font-bold w-96">
            {{'pages.installation.title' | translate }}
          </p>
          <p class="text-sm font-normal py-2">
            {{'pages.installation.description' | translate }}
          </p>
        </div>

        <div class="w-full text-[#606A76]">
          <p class="text-sm font-bold">{{'pages.installation.browsers.safari.name' | translate }}</p>
          <p class="inline-flex items-center gap-1">1. {{'pages.installation.browsers.safari.steps.1' | translate }}<zui-icon-common-share-ios></zui-icon-common-share-ios></p>
          <p>2. {{'pages.installation.browsers.safari.steps.2' | translate }}</p>
          <p>3. {{'pages.installation.browsers.safari.steps.3' | translate }}</p>
        </div>

        <div class="w-full text-[#606A76]">
          <p class="text-sm font-bold">{{'pages.installation.browsers.edge.name' | translate }}</p>
          <p class="inline-flex items-center">1. {{'pages.installation.browsers.edge.steps.1' | translate }}</p>
          <p>2. {{'pages.installation.browsers.edge.steps.2' | translate }}</p>
          <p>3. {{'pages.installation.browsers.edge.steps.3' | translate }}</p>
        </div>

        <div class="w-full text-[#606A76]">
          <p class="text-sm font-bold">{{'pages.installation.browsers.chrome.name' | translate }}</p>
          <p class="inline-flex items-center gap-1">1. {{'pages.installation.browsers.chrome.steps.1' | translate }}<zui-icon-common-share-ios></zui-icon-common-share-ios></p>
          <p>2. {{'pages.installation.browsers.chrome.steps.2' | translate }}</p>
          <p>3. {{'pages.installation.browsers.chrome.steps.3' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full h-full">
    <img class="w-full h-full object-cover" src="assets/images/home/sales-app background hd.png" alt="Home Image">
    <div class="absolute top-0 bottom-0 left-0 right-0">
      <div class="relative w-full flex justify-start">
        <div class="w-full ipad-landscape:w-1/2 mt-24 px-8">
          <h1 class="text-xl font-normal text-left">{{ 'pages.home.title' | translate }}</h1>
            <p class="text-md font-normal pt-8 text-left">{{ 'pages.home.description' | translate }}</p>

          <div class="mt-16 flex justify-start">
            <zui-button size="l" title="Start determining values" emphasis="primary-highlight" (click)="startWorkflow()">{{ 'pages.home.buttons.start' | translate }}</zui-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
