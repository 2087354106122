import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { NetworkStatus } from '@typings/network-status.enum';
import { ApplicationCache } from '@utils/cache.utils';
import { CacheStatus } from '@typings/cache-status.enum';
import { ZeissIdService } from '@planbgmbh/zeiss-id-angular-library';
import { Logger } from '@utils/logging.utils';
import { ApiService } from './api.service';
import { MeasurementDTO } from '@typings/measurement.dto.type';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  /**
   * Built-In cache for Measurements when offline
   */
  private readonly _measurementsCache = new ApplicationCache<MeasurementDTO>('measurements');

  /**
   * Event based observable for the network status
   * Tracks the network status of the application
   */
  public networkState$ = new BehaviorSubject<NetworkStatus>(navigator.onLine ? NetworkStatus.Online : NetworkStatus.Offline);

  /**
   * Event based observable for the cache status
   */
  public cacheState$: ReplaySubject<CacheStatus> = new ReplaySubject<CacheStatus>();

  constructor(private readonly _auth: ZeissIdService, private readonly _api: ApiService) {
    window.addEventListener('online', () => {
      this.networkState$.next(NetworkStatus.Online);
      Logger.debug('Restarting session checks');
      this._auth.internal.oAuthService.refreshToken();
    });
    window.addEventListener('offline', () => this.networkState$.next(NetworkStatus.Offline));

    this.cacheState$.next(this._measurementsCache.length > 0 ? 'Pending' : 'Done');
  }

  /**
   * Get the cache for Measurements
   */
  public get cache(): ApplicationCache<MeasurementDTO> {
    return this._measurementsCache;
  }

  /**
   * Synchronize the cache with the API and set the cache state to 'Synchronizing'
   * Updates all items in paralell
   */
  public async synchronizeCache() {
    this.cacheState$.next('Synchronizing');

    const queue: Promise<boolean>[] = [];

    for(const item of this.cache.cache) {
      // If the item is already done, skip it
      if(item.state === 'done') continue;

      // Wrapping the promise in a new promise to make sure the queue is not empty before all promises resolves
      const p = new Promise<boolean>(async (resolve, reject) => {
        Logger.debug('Synchronizing...', item);
        item.state = 'pending';


        const result = await this._api.saveMeasurement(item);

        if(!result.ok) {
          Logger.error('Error while synchronizing', result.result.error);
          item.state = 'cached';
          reject(result.result);
        } else {
          setTimeout(() => {
            item.state = 'done';
            resolve(true);
          }, Math.random() * 1000);
        }
      });

      queue.push(p);
    }

    try {
      await Promise.all(queue);
      this.cacheState$.next('Done');
    } catch(error) {
      Logger.error('Error while synchronizing', error);
      this.cacheState$.next('Pending');
    }
  }
  
}
