<ng-container>
  <app-step-indicator [steps]="steps" current="refraction" (interact)="interactWithStepIndicator($event)"></app-step-indicator>
  <!-- Page -->
  <div class="relative overflow-y-scroll px-8" style="height: calc(100% - 128px - 48px)">
    <!-- Title Text -->
    <div class="flex w-full py-6">
      <p class="text-sm text-[#353D45] font-semibold">{{'pages.prescription.title' | translate }}</p>
      <app-close class="ml-auto"></app-close>
    </div>

    <!-- Container -->
    <div class="w-full">

      <!-- Form -->
      <div class="w-full self-stretch">
        <form [formGroup]="refractionForm" class="w-full h-full flex flex-col" [ngClass]="{'[&>*]:!text-zeiss-grey [&>*]:opacity-60': refractionForm.disabled}">
          <!-- Row -->
          <div class="flex">
            <!-- Stretched -->
            <div class="self-stretch w-40 flex px-8 border-1 border-zeiss-grey">
              <p class="subtitle self-center">{{'pages.prescription.form.carriereLense' | translate}}</p>
            </div>
            <!-- Stack Container -->
            <div class="flex flex-col grow">
              <!-- Stack Row -->
              <div class="w-full flex items-center [&>*]:h-full [&>*]:p-2 [&>*]:border-r-1 [&>*]:border-y-1 [&>*]:border-zeiss-grey">
                <!-- Prefix Label -->
                <div class="w-24 flex items-center justify-center h-full">
                  <p class="subtitle">{{ 'pages.centration.form.right' | translate}}</p>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.sphere' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.carrierSphereR" [unit]="'units.dpt'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: -10.0, max: 6.0, steps: 0.25} }}
                  </app-form-field>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.cylinder' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.carrierCylinderR" [unit]="'units.dpt'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: -4.0, max: 4.0, steps: 0.25} }}
                  </app-form-field>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.axis' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.carrierAxisR" [unit]="'units.deg'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: 0.0, max: 180.0, steps: 1.0} }}
                  </app-form-field>
                </div>

              </div>
              <!-- Stack Row -->
              <div class="w-full flex items-center [&>*]:h-full [&>*]:p-2 [&>*]:border-r-1 [&>*]:border-b-1 [&>*]:border-zeiss-grey">
                <!-- Prefix Label -->
                <div class="w-24 flex items-center justify-center h-full border-r-1 border-zeiss-grey">
                  <p class="subtitle">{{ 'pages.centration.form.left' | translate}}</p>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.sphere' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.carrierSphereL" [unit]="'units.dpt'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: -10.0, max: 6.0, steps: 0.25} }}
                  </app-form-field>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.cylinder' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.carrierCylinderL" [unit]="'units.dpt'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: -4.0, max: 4.0, steps: 0.25} }}
                  </app-form-field>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.axis' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.carrierAxisL" [unit]="'units.deg'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: 0, max: 180.0, steps: 1.0} }}
                  </app-form-field>
                </div>

              </div>
            </div>
          </div>

          <!-- Row -->
          <div class="flex">
            <!-- Stretched -->
            <div class="self-stretch w-40 flex px-8 border-x-1 border-b-1 border-zeiss-grey">
              <p class="subtitle self-center">{{'pages.prescription.form.ocularLense' | translate}}</p>
            </div>
            <!-- Stack Container -->
            <div class="flex flex-col grow">
              <!-- Stack Row -->
              <div class="w-full flex items-center [&>*]:h-full [&>*]:p-2 [&>*]:border-r-1 [&>*]:border-b-1 [&>*]:border-zeiss-grey">
                <!-- Prefix Label -->
                <div class="w-24 flex items-center justify-center h-full">
                  <p class="subtitle">{{ 'pages.centration.form.right' | translate}}</p>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.sphere' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.ocularSphereR" [unit]="'units.dpt'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: -10.0, max: 6.0, steps: 0.25} }}
                  </app-form-field>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.cylinder' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.ocularCylinderR" [unit]="'units.dpt'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: -4.0, max: 4.0, steps: 0.25} }}
                  </app-form-field>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.axis' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.ocularAxisR" [unit]="'units.deg'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: 0.0, max: 180.0, steps: 1.0} }}
                  </app-form-field>
                </div>

              </div>
              <!-- Stack Row -->
              <div class="w-full flex items-center [&>*]:h-full [&>*]:p-2 [&>*]:border-r-1 [&>*]:border-b-1 [&>*]:border-zeiss-grey">
                <!-- Prefix Label -->
                <div class="w-24 flex items-center justify-center h-full border-r-1 border-zeiss-grey">
                  <p class="subtitle">{{ 'pages.centration.form.left' | translate}}</p>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.sphere' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.ocularSphereL" [unit]="'units.dpt'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: -10.0, max: 6.0, steps: 0.25} }}
                  </app-form-field>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.cylinder' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.ocularCylinderL" [unit]="'units.dpt'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: -4.0, max: 4.0, steps: 0.25} }}
                  </app-form-field>

                  <zui-button size="l" (click)="cylinderCalculation()" class="translate-y-20 absolute">
                    <span>{{'pages.prescription.cylinderCalculation' | translate}}</span>
                  </zui-button>
                </div>

                <!-- Input Block -->
                <div class="h-full flex items-center grow justify-evenly gap-4">
                  <!-- Label -->
                  <div>
                    <p class="subtitle">{{'pages.prescription.form.axis' | translate}}</p>
                  </div>

                  <!-- Input -->
                  <app-form-field [control]="refractionForm.controls.ocularAxisL" [unit]="'units.deg'|translate">
                    {{ 'validation.generic.valueRange' | translate : {min: 0.0, max: 180.0, steps: 1.0} }}
                  </app-form-field>
                </div>

              </div>
            </div>
          </div>
        </form>

        <!-- Row -->
        <div class="flex py-5 items-center">
          <zui-checkbox ngDefaultControl [(ngModel)]="skipFlag">
            <span>{{'pages.prescription.skipInputs'|translate}}</span>
          </zui-checkbox>
        </div>
      </div>
    </div>
  </div>

  <app-bottom-bar [nextPageValid]="refractionValidation" (nextPage)="nextPage()" (previousPage)="previousPage()"></app-bottom-bar>
</ng-container>
