<ng-container>
  <div class="absolute bottom-0 w-screen h-32 border-t-4 border-t-bottombar-border bg-bottombar-background">
    <zui-button *ngIf="lastPage !== 'home'" class="float-left mt-8 ml-8" size="l"
                emphasis="default" (click)="previousClick()">
      {{'components.bottomBar.previous' | translate}}
    </zui-button>
    <zui-button *ngIf="lastPage !== 'ergonomics'" [disabled]="!nextPageValid" class="float-right mt-8 mr-8" size="l"
                emphasis="default" (click)="buttonClick()">
                {{'components.bottomBar.next' | translate}}
    </zui-button>
    <zui-button *ngIf="lastPage === 'ergonomics'" [disabled]="!nextPageValid" class="float-right mt-8 mr-8" size="l"
                emphasis="primary-highlight" (click)="sendDataClick()">
                {{'components.bottomBar.send' | translate}}
    </zui-button>
  </div>
</ng-container>
