import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {ApiService} from "@services/api.service";

@Component({
  selector: 'app-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.css']
})
export class CloseComponent {
  public dialogOpen: boolean = false;

  constructor(private _router: Router, private _apiService: ApiService) {
  }

  public openDialog(): void {
    this.dialogOpen = true;
  }

  public closeDialog(): void {
    this.dialogOpen = false;
  }

  public closeConfig(): void {
    this._apiService.initializeMeasurementFactory();
    this._router.navigate(['/home']);
  }
}
