<ng-container>
  <!-- <div class="flex justify-start w-24 gap-3">
    <p>{{prefix}}</p>
    <p><ng-content></ng-content></p>
    <p>{{unit}}</p>
  </div> -->
  <table class="w-24">
    <tr>
      <td class="w-2">{{prefix}}</td>
      <td class="w-16 text-end pr-1"><ng-content></ng-content></td>
      <td class="w-6 text-left">{{unit}}</td>
    </tr>
  </table>
</ng-container>
