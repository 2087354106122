import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@utils/logging.utils';

@Pipe({
    name: 'annotation'
})

/**
 * Transform any number to a decimal with a fixed number of floating points
 * config: [floatingPoints: number] (default: 2)
 */
export class AnnotationPipe implements PipeTransform {

    constructor(private readonly _translate: TranslateService) { }

    transform(value: any, ...args: [floatingPoints: number]): any {
        const floatingPoints = args[0] || 2;
        Logger.debug(`AnnotationPipe: transform ${value} with ${args} floating point(s)`);
        let v = value;
        let sign = '';

        if(v === undefined || v === null || v === '') { 
            Logger.debug(`AnnotationPipe: ${v} is undefined or null`);
            return this._translate.instant('units.notAvailable');
        }

        if(typeof v === 'string') {
            Logger.debug(`AnnotationPipe: ${v} is a string`);
            const {sign: formattedSign, value} = this.formatSingedInput(v);
            sign = formattedSign;
            v = parseFloat(value.replace(',', '.'));
        }

        if(typeof v === 'number') { 
            Logger.debug(`AnnotationPipe: ${v} is a number`);
            return `${sign}${v.toLocaleString(navigator.language, {minimumFractionDigits: floatingPoints, maximumFractionDigits: floatingPoints})}`;
        };

        Logger.debug(`AnnotationPipe: ${v} is not a number nor undefined or null`);
        return v.toLocaleString(navigator.language, {minimumFractionDigits: floatingPoints, maximumFractionDigits: floatingPoints});
    }

    private formatSingedInput(value: string): {value: string, sign: string} {
        if(value.startsWith('+')) {
            return {value: value.slice(1), sign: '+'};
        }

        return {value, sign: ''};
    }
}