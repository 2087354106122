<ng-container>
  <app-step-indicator [steps]="steps" current="summary"
                      (interact)="interactWithStepIndicator($event)"></app-step-indicator>

  <div *ngIf="dialogOpen" class="fixed top-0 bottom-0 left-0 right-0 z-50 backdrop-brightness-50">
    <zui-dialogbox
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-96"
      [confirmPosition]="'right'"
      [headerText]="'pages.summary.confirm.title' | translate" closable="true"
      (zui-dialog-box-accepted)="sendData()"
      (zui-dialog-box-cancelled)="closeDialog()"
      [disableAccept]="loading || !summaryFormGroup.valid"
      [disableCancel]="loading"
    >
      <div class="absolute top-0 bottom-0 right-0 left-0 z-10 pt-5 pb-5" *ngIf="loading && !dataSend">
        <zui-progress-ring size="s80" class="w-full h-full" emphasis="highlight" mode="activity"></zui-progress-ring>
      </div>

      <div class="absolute top-0 bottom-0 right-0 left-0 z-10 pt-5 pb-5" *ngIf="!loading && dataSend">
        <div class="flex justify-center">
          <zui-icon-alerts-ewi-check size="s32"></zui-icon-alerts-ewi-check>
          <p></p>
        </div>
      </div>

      <p class="pt-4 space-y-2" *ngIf="!loading && !dataSend">{{ 'pages.summary.confirm.description' | translate }}</p>

      <form class="pt-8" [formGroup]="summaryFormGroup" *ngIf="!loading && !dataSend">
        <app-form-field [full]="true" [autoformat]="false" [control]="summaryFormGroup.controls.customerId">{{'validation.length'| translate : {length: 30} }}</app-form-field>
      </form>

    </zui-dialogbox>
  </div>

  <div *ngIf="dataSend" class="fixed top-0 bottom-0 left-0 right-0 z-50 backdrop-brightness-50">
    <zui-dialogbox
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-80"
      [confirmPosition]="'right'"
      [hideCancelButton]="true"
      [headerText]="'pages.summary.confirm.title'|translate" closable="true"
      (zui-dialog-box-accepted)="closeSuccess()"
    >

      <div class="absolute top-0 bottom-0 right-0 left-0 z-10 pt-5 pb-5">
        <div class="flex flex-col">
          <zui-icon-alerts-ewi-check class="self-center" size="s32"></zui-icon-alerts-ewi-check>
          <p class="self-center text-center pt-5">{{ 'pages.summary.confirm.saved' | translate }}</p>
        </div>
      </div>

    </zui-dialogbox>
  </div>

  <!--  Page -->
  <div class="relative overflow-y-scroll px-8 pb-10" style="height: calc(100% - 128px - 48px);">
    <!-- Title Text -->
    <div class="flex w-full py-6">
      <p class="text-sm text-[#353D45] font-semibold">{{'pages.summary.title' | translate }}</p>
      <app-close class="ml-auto"></app-close>
    </div>

    <!-- Container -->
    <div class="w-full relative flex">
      <!--  Left  -->
      <div class="w-1/4">
        <div class="w-full self-stretch">
          <div class="w-full bg-information-box h-full p-4 flex flex-col justify-between">
            <div class="w-full pb-10">
              <zui-icon-alerts-ewi-info size="s24" class="grayscale"></zui-icon-alerts-ewi-info>
            </div>
            <div class="w-full pr-8">
              <p class="text-xs font-normal">{{'pages.summary.info_box' | translate }}</p>
            </div>
          </div>
        </div>
      </div>

      <!--  Right  -->
      <div class="w-3/4 flex flex-col gap-8 px-4">

        <!-- Container -->
        <div class="w-full bg-[#ECF0F4]">
          <!-- Header -->
          <div class="w-full bg-[#E6EBF0] px-4 py-1 border-[#C2CDD6] border-x-1 border-t-1">
            <p class="text-sm font-semibold text-[#32373E]">{{ 'pages.summary.boxes.ergonomic.header' | translate}}</p>
          </div>

          <!-- Rows -->
          <div class="w-full">
            <!-- Row 1 -->
            <div
              class="w-full flex items-center justify-between px-4 py-3 text-sm font-normal text-[#4E565F] border-x-1 border-t-1 border-[#C2CDD6]">
              <p>{{'pages.summary.boxes.ergonomic.distanceEyeTeeth'|translate}}</p>
              <app-value-box
                [unit]="'units.mm' | translate">{{(_apiService.measurementFactory.ergonomics?.workingDistance | annotation : 2)}}</app-value-box>
            </div>

            <!-- Row 2 -->
            <div
              class="w-full flex items-center justify-between px-4 py-3 text-sm font-normal text-[#4E565F] border-x-1 border-y-1 border-[#C2CDD6]">
              <p>{{'pages.summary.boxes.ergonomic.angleOfView'|translate}}</p>
              <app-value-box
                [unit]="'units.deg' | translate">{{(_apiService.measurementFactory.ergonomics?.declinationAngle | annotation : 2)}}</app-value-box>
            </div>

          </div>
        </div>

        <!-- Container -->
        <div class="w-full bg-[#ECF0F4]">
          <!-- Header -->
          <div class="w-full bg-[#E6EBF0] px-4 py-1 border-[#C2CDD6] border-x-1 border-t-1">
            <p class="text-sm font-semibold text-[#32373E]">{{ 'pages.summary.boxes.centration.header' | translate}}</p>
          </div>

          <!-- Rows -->
          <div class="w-full">
            <!-- Row 1 -->
            <div
              class="w-full flex items-center justify-between text-sm font-normal text-[#4E565F] border-x-1 border-t-1 border-[#C2CDD6]">

              <div class="w-16 border-[#C2CDD6] border-r-1 py-3 px-4">
                <p>{{'pages.summary.boxes.centration.right'|translate}}</p>
              </div>

              <!-- Combined Box -->
              <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow flex-wrap flex-row-reverse">
                <p>{{'pages.summary.boxes.centration.pupilDistance'|translate}}</p>
                <app-value-box
                  [unit]="'units.mm' | translate">{{(_apiService.measurementFactory.centration?.pupilDistance?.right| annotation : 2)}}</app-value-box>
              </div>

              <!-- Combined Box -->
              <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow flex-wrap flex-row-reverse">
                <p>{{'pages.summary.boxes.centration.pupilHeight'|translate}}</p>
                <app-value-box
                  [unit]="'units.mm' | translate">{{(_apiService.measurementFactory.centration?.pupilHeight?.right| annotation : 2)}}</app-value-box>
              </div>

              <!-- Combined Box -->
              <div class="flex justify-between items-center border-[#C2CDD6] py-3 px-4 grow flex-wrap flex-row-reverse">
                <p>{{'pages.summary.boxes.centration.pupilBVD'|translate}}</p>
                <app-value-box
                  [unit]="'units.mm' | translate">{{(_apiService.measurementFactory.centration?.bvd?.right| annotation : 2)}}</app-value-box>
              </div>

            </div>

            <!-- Row 2 -->
            <div
              class="w-full flex items-center justify-between text-sm font-normal text-[#4E565F] border-x-1 border-y-1 border-[#C2CDD6]">
              <div class="w-16 border-[#C2CDD6] border-r-1 py-3 px-4">
                <p>{{'pages.summary.boxes.centration.left'|translate}}</p>
              </div>

              <!-- Combined Box -->
              <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow flex-wrap flex-row-reverse">
                <p>{{'pages.summary.boxes.centration.pupilDistance'|translate}}</p>
                <app-value-box
                  [unit]="'units.mm' | translate">{{(_apiService.measurementFactory.centration?.pupilDistance?.left| annotation : 2)}}</app-value-box>
              </div>

              <!-- Combined Box -->
              <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow flex-wrap flex-row-reverse">
                <p>{{'pages.summary.boxes.centration.pupilHeight'|translate}}</p>
                <app-value-box
                  [unit]="'units.mm' | translate">{{(_apiService.measurementFactory.centration?.pupilHeight?.left| annotation : 2)}}</app-value-box>
              </div>

              <!-- Combined Box -->
              <div class="flex justify-between items-center  py-3 px-4 grow flex-wrap flex-row-reverse">
                <p>{{'pages.summary.boxes.centration.pupilBVD'|translate}}</p>
                <app-value-box
                  [unit]="'units.mm' | translate">{{(_apiService.measurementFactory.centration?.bvd?.left| annotation : 2)}}</app-value-box>
              </div>
            </div>

          </div>
        </div>

        <!-- Container -->
        <div class="w-full bg-[#ECF0F4]">
          <!-- Header -->
          <div class="w-full bg-[#E6EBF0] px-4 py-1 border-[#C2CDD6] border-x-1 border-y-1 flex justify-between">
            <p
              class="text-sm font-semibold text-[#32373E]">{{ 'pages.summary.boxes.prescription.header' | translate}}</p>
            <p *ngIf="_apiService.measurementFactory.refraction?.skiped" class="text-sm text-[#32373E] flex gap-2">
              <zui-icon-alerts-ewi-warning-triangle size="s16"></zui-icon-alerts-ewi-warning-triangle>
              {{'pages.summary.boxes.prescription.skipInputs'|translate}}</p>
          </div>

          <!-- Rows -->
          <div class="w-full" [ngClass]="{'[&>*]:!opacity-40': _apiService.measurementFactory.refraction?.skiped}">
            <!-- Row 1 -->
            <div
              class="w-full flex items-center justify-between text-sm font-normal text-[#4E565F] border-x-1 border-[#C2CDD6]">

              <div class="self-stretch flex w-32 px-4 justify-center border-r-1 border-b-1 border-[#C2CDD6]">
                <p class="self-center text-center">{{'pages.summary.boxes.prescription.carriereLense'|translate}}</p>
              </div>

              <!-- Combine Container -->
              <div class="flex flex-col grow">
                <!-- Combine Container Row -->
                <div class="flex items-center border-[#C2CDD6] border-b-1">
                  <div class="w-16 border-[#C2CDD6] border-r-1 py-3 px-4">
                    <p>{{'pages.summary.boxes.centration.right'|translate}}</p>
                  </div>

                  <!-- Combined Box -->
                  <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow">
                    <p>{{'pages.summary.boxes.prescription.sphere'|translate}}</p>
                    <app-value-box
                      [unit]="'units.dpt' | translate">{{(_apiService.measurementFactory.refraction?.carrierLense?.sphere?.right| annotation : 2)}}</app-value-box>
                  </div>

                  <!-- Combined Box -->
                  <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow">
                    <p>{{'pages.summary.boxes.prescription.cylinder'|translate}}</p>
                    <app-value-box [unit]="'units.dpt'|translate">{{(_apiService.measurementFactory.refraction?.carrierLense?.cylinder?.right| annotation : 2)}}</app-value-box>
                  </div>

                  <!-- Combined Box -->
                  <div class="flex justify-between items-center border-[#C2CDD6] py-3 px-4 grow">
                    <p>{{'pages.summary.boxes.prescription.axis'|translate}}</p>
                    <app-value-box
                      [unit]="'units.deg' | translate">{{(_apiService.measurementFactory.refraction?.carrierLense?.axis?.right| annotation : 2)}}</app-value-box>
                  </div>
                </div>

                <!-- Combine Container -->
                <div class="flex flex-col">
                  <!-- Combine Container Row -->
                  <div class="flex items-center border-[#C2CDD6] border-b-1">
                    <div class="w-16 border-[#C2CDD6] border-r-1 py-3 px-4">
                      <p>{{'pages.summary.boxes.prescription.left'|translate}}</p>
                    </div>

                    <!-- Combined Box -->
                    <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow">
                      <p>{{'pages.summary.boxes.prescription.sphere'|translate}}</p>
                      <app-value-box
                        [unit]="'units.dpt' | translate">{{(_apiService.measurementFactory.refraction?.carrierLense?.sphere?.left| annotation : 2)}}</app-value-box>
                    </div>

                    <!-- Combined Box -->
                    <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow">
                      <p>{{'pages.summary.boxes.prescription.cylinder'|translate}}</p>
                      <app-value-box [unit]="'units.dpt'|translate">{{(_apiService.measurementFactory.refraction?.carrierLense?.cylinder?.left| annotation : 2)}}</app-value-box>
                    </div>

                    <!-- Combined Box -->
                    <div class="flex justify-between items-center border-[#C2CDD6] py-3 px-4 grow">
                      <p>{{'pages.summary.boxes.prescription.axis'|translate}}</p>
                      <app-value-box
                        [unit]="'units.deg' | translate">{{(_apiService.measurementFactory.refraction?.carrierLense?.axis?.left| annotation : 2)}}</app-value-box>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Row 2 -->
            <div
              class="w-full flex items-center justify-between text-sm font-normal text-[#4E565F] border-x-1  border-[#C2CDD6]">

              <div class="self-stretch flex w-32 px-4 justify-center border-r-1 border-b-1 border-[#C2CDD6]">
                <p class="self-center text-center">{{'pages.summary.boxes.prescription.ocularLense'|translate}}</p>
              </div>

              <!-- Combine Container -->
              <div class="flex flex-col grow">
                <!-- Combine Container Row -->
                <div class="flex items-center border-[#C2CDD6] border-b-1">
                  <div class="w-16 border-[#C2CDD6] border-r-1 py-3 px-4">
                    <p>{{'pages.summary.boxes.prescription.right'|translate}}</p>
                  </div>

                  <!-- Combined Box -->
                  <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow">
                    <p>{{'pages.summary.boxes.prescription.sphere'|translate}}</p>
                    <app-value-box
                      [unit]="'units.dpt' | translate">{{(_apiService.measurementFactory.refraction?.ophthalmicLense?.sphere?.right| annotation : 2)}}</app-value-box>
                  </div>

                  <!-- Combined Box -->
                  <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow">
                    <p>{{'pages.summary.boxes.prescription.cylinder'|translate}}</p>
                    <app-value-box [unit]="'units.dpt'|translate">{{(_apiService.measurementFactory.refraction?.ophthalmicLense?.cylinder?.right| annotation : 2)}}</app-value-box>
                  </div>

                  <!-- Combined Box -->
                  <div class="flex justify-between items-center border-[#C2CDD6] py-3 px-4 grow">
                    <p>{{'pages.summary.boxes.prescription.axis'|translate}}</p>
                    <app-value-box
                      [unit]="'units.deg' | translate">{{(_apiService.measurementFactory.refraction?.ophthalmicLense?.axis?.right| annotation : 2)}}</app-value-box>
                  </div>
                </div>

                <!-- Combine Container -->
                <div class="flex flex-col">
                  <!-- Combine Container Row -->
                  <div class="flex items-center border-[#C2CDD6] border-b-1">
                    <div class="w-16 border-[#C2CDD6] border-r-1 py-3 px-4">
                      <p>{{'pages.summary.boxes.prescription.left'|translate}}</p>
                    </div>

                    <!-- Combined Box -->
                    <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow">
                      <p>{{'pages.summary.boxes.prescription.sphere'|translate}}</p>
                      <app-value-box
                        [unit]="'units.dpt' | translate">{{(_apiService.measurementFactory.refraction?.ophthalmicLense?.sphere?.left| annotation : 2)}}</app-value-box>
                    </div>

                    <!-- Combined Box -->
                    <div class="flex justify-between items-center border-[#C2CDD6] border-r-1 py-3 px-4 grow">
                      <p>{{'pages.summary.boxes.prescription.cylinder'|translate}}</p>
                      <app-value-box [unit]="'units.dpt'|translate">{{(_apiService.measurementFactory.refraction?.ophthalmicLense?.cylinder?.left| annotation : 2)}}</app-value-box>
                    </div>

                    <!-- Combined Box -->
                    <div class="flex justify-between items-center border-[#C2CDD6] py-3 px-4 grow">
                      <p>{{'pages.summary.boxes.prescription.axis'|translate}}</p>
                      <app-value-box
                        [unit]="'units.deg' | translate">{{(_apiService.measurementFactory.refraction?.ophthalmicLense?.axis?.left| annotation : 2)}}</app-value-box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-bottom-bar (sendData)="openDialog()" [lastPage]="'ergonomics'" (previousPage)="previousPage()"></app-bottom-bar>
</ng-container>
