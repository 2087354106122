<ng-container>
  <app-step-indicator [steps]="steps" current="centration" (interact)="interactWithStepIndicator($event)"></app-step-indicator>
  <!-- Page -->
  <div class="relative overflow-y-scroll px-8" style="height: calc(100% - 128px - 48px)">
    <!-- Title Text -->
    <div class="flex w-full py-6">
      <p class="text-sm text-[#353D45] font-semibold">{{'pages.centration.title' | translate }}</p>
      <app-close class="ml-auto"></app-close>
    </div>

    <!-- Container -->
    <div class="w-full flex gap-4 justify-evenly items-center">

      <!-- Form -->
      <div class="w-full self-stretch">
        <form [formGroup]="centrationForm" class="w-full h-full flex flex-col">
          <!-- Row -->
          <div class="flex flex-col items-end border-x-1 border-t-1 border-input-boxes-border">
            <div class="w-full flex items-center [&>*]:h-full [&>*]:p-2">

              <!-- Prefix Label -->
              <div class="w-24 flex items-center justify-center h-full border-r-1 border-zeiss-grey">
                <p class="subtitle">{{ 'pages.centration.form.right' | translate}}</p>
              </div>

              <!-- Input Block -->
              <div class="h-full flex items-center gap-4 grow justify-evenly border-r-1 border-zeiss-grey">
                <!-- Label -->
                <div>
                  <p class="subtitle">{{'pages.centration.form.pupilDistance' | translate}}</p>
                  <p class="subtitle">{{'pages.centration.form.pupilDistanceShort' | translate}}</p>
                </div>

                <!-- Input -->
                <app-form-field [control]="centrationForm.controls.pupilDistanceR" [unit]="'units.mm'|translate">
                  {{ 'validation.generic.valueRange' | translate : {min: 20, max: 40, steps: 0.1} }}
                </app-form-field>
              </div>

              <!-- Input Block -->
              <div class="h-full flex items-center gap-4 grow justify-evenly border-r-1 border-zeiss-grey">
                <!-- Label -->
                <div>
                  <p class="subtitle">{{'pages.centration.form.pupilHeight' | translate}}</p>
                  <p class="subtitle">{{'pages.centration.form.pupilHeightShort' | translate}}</p>
                </div>

                <!-- Input -->
                <app-form-field [control]="centrationForm.controls.pupilHeightR" [unit]="'units.mm'|translate">
                  {{ 'validation.generic.valueRange' | translate : {min: 10, max: 45, steps: 0.1} }}
                </app-form-field>
              </div>

              <!-- Input Block -->
              <div class="h-full flex items-center grow justify-evenly gap-4">
                <!-- Label -->
                <div>
                  <p class="subtitle">{{'pages.centration.form.pupilBVD' | translate}}</p>
                  <p class="subtitle">{{'pages.centration.form.pupilBVDShort' | translate}}</p>
                </div>

                <!-- Input -->
                <app-form-field [control]="centrationForm.controls.pupilBVDR" (change)="fieldComponentChanged('bvd_r', $event)" [unit]="'units.mm'|translate">
                  {{ 'validation.generic.valueRange' | translate : {min: 5.0, max: 20.0, steps: 0.1} }}
                </app-form-field>
              </div>

            </div>
          </div>

          <!-- Row -->
          <div class="flex flex-col items-end border-x-1 border-y-1 border-input-boxes-border">
            <div class="w-full flex items-center [&>*]:h-full [&>*]:p-2">

              <!-- Prefix Label -->
              <div class="w-24 flex items-center justify-center h-full border-r-1 border-zeiss-grey">
                <p class="subtitle">{{ 'pages.centration.form.left' | translate}}</p>
              </div>

              <!-- Input Block -->
              <div class="h-full flex items-center gap-4 grow justify-evenly border-r-1 border-zeiss-grey">
                <!-- Label -->
                <div>
                  <p class="subtitle">{{'pages.centration.form.pupilDistance' | translate}}</p>
                  <p class="subtitle">{{'pages.centration.form.pupilDistanceShort' | translate}}</p>
                </div>

                <!-- Input -->
                <app-form-field [control]="centrationForm.controls.pupilDistanceL" [unit]="'units.mm'|translate">
                  {{ 'validation.generic.valueRange' | translate : {min: 20, max: 40, steps: 0.1} }}
                </app-form-field>
              </div>

              <!-- Input Block -->
              <div class="h-full flex items-center gap-4 grow justify-evenly border-r-1 border-zeiss-grey">
                <!-- Label -->
                <div>
                  <p class="subtitle">{{'pages.centration.form.pupilHeight' | translate}}</p>
                  <p class="subtitle">{{'pages.centration.form.pupilHeightShort' | translate}}</p>
                </div>

                <!-- Input -->
                <app-form-field [control]="centrationForm.controls.pupilHeightL" [unit]="'units.mm'|translate">
                  {{ 'validation.generic.valueRange' | translate : {min: 10, max: 45, steps: 0.1} }}
                </app-form-field>
              </div>

              <!-- Input Block -->
              <div class="h-full flex items-center grow justify-evenly gap-4">
                <!-- Label -->
                <div>
                  <p class="subtitle">{{'pages.centration.form.pupilBVD' | translate}}</p>
                  <p class="subtitle">{{'pages.centration.form.pupilBVDShort' | translate}}</p>
                </div>

                <!-- Input -->
                <app-form-field [control]="centrationForm.controls.pupilBVDL" (change)="fieldComponentChanged('bvd_l', $event)" [unit]="'units.mm'|translate">
                  {{ 'validation.generic.valueRange' | translate : {min: 5.0, max: 20.0, steps: 0.1} }}
                </app-form-field>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <app-bottom-bar [nextPageValid]="centrationForm.valid" (nextPage)="nextPage()" (previousPage)="previousPage()"></app-bottom-bar>
</ng-container>
