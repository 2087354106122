import { LogLevel } from '@typings/log-level.enum';
import { MonoTypeOperatorFunction, Observable, tap } from 'rxjs';
import { Logger } from './logging.utils';

/**
 * Log the value of the Observable to the console
 * 
 * @param message Additional Message to log as prefix before the vlaue
 * @param level LogLevel to use for the log message
 * @returns MonoTypeOperatorFunction<T>
 */
export function console<T>(
  message: string,
  level: LogLevel
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>): Observable<T> => {
    return source.pipe(
      tap((value) => {
        Logger.formatted(level, message, value);
      })
    );
  };
}
