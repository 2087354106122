<zui-popover 
  class="fixed top-16 -translate-y-2 z-20 w-56" 
  [ngClass]="{
    'right-16 p-4': type === 'sync', 
    'right-32 p-4': type === 'offline', 
    'right-4 p-0': type === 'avatar_menu'
  }">

  <div class="flex flex-col">
    <p class="font-semibold text-sm" *ngIf="title">{{title || 'NO_TITLE'}}</p>
    <ng-content></ng-content>
  </div>
</zui-popover>
<div class="fixed top-0 bottom-0 left-0 right-0 z-10" (click)="backdropClick.emit();"></div>