import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {NavbarComponent} from "./ui-components/navbar/navbar.component";
import {StepIndicatorComponent} from './ui-components/step-indicator/step-indicator.component';
import {BottomBarComponent} from './ui-components/bottom-bar/bottom-bar.component';
import {ValueBoxComponent} from './ui-components/value-box/value-box.component';
import { InputBoxComponent } from './ui-components/input-box/input-box.component';
import { PopoverComponent } from './ui-components/popover/popover.component';
import { PreviousPipe } from './pipes/previous.pipe';
import { AnnotationPipe } from './pipes/annotation.pipe';
import { FormFieldComponent } from './ui-components/form-field/form-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CloseComponent } from './ui-components/close/close.component';


const declarations = [
  NavbarComponent,
  StepIndicatorComponent,
  BottomBarComponent,
  ValueBoxComponent,
  InputBoxComponent,
  PopoverComponent,
  PreviousPipe,
  AnnotationPipe,
  FormFieldComponent,
  CloseComponent
];

@NgModule({
  declarations: [
    ...declarations,
    CloseComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule
  ],
    exports: [
      ...declarations
    ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {
}
