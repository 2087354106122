import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { ZeissIdService } from '@planbgmbh/zeiss-id-angular-library';
import { OfflineService } from '@services/offline.service';
import { LogLevel } from '@typings/log-level.enum';
import { ApplicationCache } from '@utils/cache.utils';
import { disableAutoLogin } from '@utils/debug.utils';
import { selectedLanguage, saveLanguage } from '@utils/language.utils';
import { Logger } from '@utils/logging.utils';
import { console } from '@utils/rxjs.utils';
import { Observable, filter, map, startWith } from 'rxjs';
import {Router} from "@angular/router";
import { MeasurementDTO } from '@typings/measurement.dto.type';
import { DialogBox, GetZuiEvent } from '@zeiss/zui';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private readonly _auth: ZeissIdService,
    private readonly _update: SwUpdate,
    private readonly _offline: OfflineService,
    private readonly _translate: TranslateService,
    private readonly _router: Router
  ) {}

  /**
   * Boolean that indicates if the update dialog should be shown
   * Can be used to close the dialog without updating the application
   */
  public showDialog: boolean = true;

  /**
   * Observable that emits when a new version of the application is available
   */
  public updateAvailable$: Observable<boolean> = this._update.versionUpdates.pipe(
    console('Version Update Emitted', LogLevel.Debug),
    filter(updateEvent => updateEvent.type === "VERSION_DETECTED"),
    map(updateEvent => updateEvent.type === 'VERSION_DETECTED'),
    startWith(false)
  );

  /**
   * Observable that emits when the application is offline
   */
  public offline$: Observable<boolean> = this._offline.networkState$.pipe(
    console('Network State Emitted', LogLevel.Debug),
    map(state => state === 'offline'),
    startWith(true)
  );

  /**
   * Observable that emits the current state of the cache
   */
  public cacheState$ = this._offline.cacheState$.pipe(
    console(`Cache State Emitted`, LogLevel.Debug)
  );

  /**
   * First name  & Last name of the user if the user is logged in
   * Returns null if the user is not logged in
   */
  public get username(): string | null {
    if(this._auth.user) return this._auth.user.firstName + ' ' + this._auth.user.lastName;
    return null;
  }

  public get applicationCache(): ApplicationCache<MeasurementDTO> {
    return this._offline.cache;
  }

  public async ngOnInit(): Promise<void> {
    Logger.info(`Application is running in ${this._update.isEnabled ? 'service worker' : 'website'} mode on ${environment.production ? 'production' : 'non production'}.`);
    this._setupLanguage();

    Logger.debug(`Auto login is ${disableAutoLogin ? 'disabled' : 'enabled'}`);
    Logger.debug(`ID Token is ${this._auth.internal.oAuthService.hasValidIdToken() ? 'valid' : 'invalid'}`);
    if(!this._auth.getIdToken() && !disableAutoLogin) {
      this._auth.signIn();
    }
    await this._router.navigate(['/home']);
  }

  /**
   * Log out the user by calling the signOut method of the ZeissIdService
   * Removes the idToken from the session storage
   */
  public logout(): void {
    this._auth.signOut();
  }

  /**
   * Reload the page to update the application
   */
  public update(): void {
    window.location.reload();
  }

  public async synchronize(): Promise<void> {
    await this._offline.synchronizeCache();
  }

  public closeDialog(event: GetZuiEvent<DialogBox, 'AcceptedEvent'>): void {
    this.showDialog = false;
  }

  /**
   * Change the language of the application
   * Currently supported languages are: 'de-DE' and 'en-US'
   * The language is stored in the local storage
   * @todo Enable once language support is enabled
   */
  public changeLanguage(): void {
    Logger.debug(`Current language is ${this._translate.currentLang}`);
    if(this._translate.currentLang === 'de-DE') {
      this._translate.use('en-US');
    }
    else {
      this._translate.use('de-DE');
    }

    saveLanguage(this._translate.currentLang);
  }

  private _setupLanguage(): void {
    Logger.debug(`Language Service found ${this._translate.getLangs().length} languages`);
    this._translate.setDefaultLang('en-US');

    // Enable below code once language support is enabled in the application
    
    const browserLang = navigator.language;
    Logger.debug(`Browser language is ${browserLang}`);

    // const languageOverride = selectedLanguage;
    // Logger.debug(`Language override is ${languageOverride}`);

    const languageToUse = browserLang;
    Logger.debug(`Language to use is ${languageToUse}`);
    
    // const languageToUse = 'en-US';
    this._translate.use(languageToUse);
  }
}
