<ng-container>
  <app-step-indicator [steps]="steps" current="ergonomics" (interact)="interactWithStepIndicator($event)"></app-step-indicator>
  <!-- Page -->
  <div class="relative overflow-y-scroll px-8" style="height: calc(100% - 128px - 48px);">
    <!-- Title Text -->
    <div class="flex w-full py-6">
      <p class="text-sm text-[#353D45] font-semibold">{{'pages.ergonomic.title' | translate }}</p>
      <app-close class="ml-auto"></app-close>
    </div>

    <!-- Container -->
    <div class="w-full flex gap-4 justify-evenly items-center">
      <!--  Image -->
      <div class="w-1/4 self-stretch">
        <img src="assets/images/ergonomics/ergonomics.svg" class="w-full object-cover" alt="Image of the measurement">
      </div>

      <!-- Info Box -->
      <div class="w-1/4 self-stretch">
        <div class="w-full bg-information-box h-full p-4 flex flex-col justify-between">
          <div class="w-full">
            <zui-icon-alerts-ewi-info size="s24" class="grayscale"></zui-icon-alerts-ewi-info>
          </div>
          <div class="w-full pr-8">
            <p class="text-xs font-normal">{{'pages.ergonomic.info_box' | translate }}</p>
          </div>
        </div>
      </div>

      <!-- Form -->
      <div class="w-1/2 self-stretch">
        <form [formGroup]="ergonomicsForm" class="w-full h-full flex flex-col">
          <!-- Row -->
          <div class="flex flex-col items-end border-x-1 border-t-1 px-8 py-4 border-input-boxes-border">
            <div class="w-full flex justify-between items-center">
              <!-- Label -->
              <div>
                <p class="subtitle">{{'pages.ergonomic.form.distanceEyeTeeth' | translate}}</p>
              </div>
                <app-form-field [control]="ergonomicsForm.controls.distanceEyeTeeth" [unit]="'units.mm'|translate">
                  {{ 'validation.generic.stepRange' | translate : {steps: 10} }}
                </app-form-field>
            </div>
          </div>

          <!-- Row -->
          <div class="flex flex-col items-end border-1 px-8 py-4 border-input-boxes-border">
            <div class="w-full flex justify-between items-center">

              <div>
                <p class="subtitle">{{'pages.ergonomic.form.angleOfView' | translate}}</p>
              </div>

              <app-form-field [control]="ergonomicsForm.controls.angleOfView" [unit]="'units.deg'|translate">
                {{ 'validation.generic.valueRange' | translate : {min: 0, max: 30, steps: 5} }}
              </app-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  
  <app-bottom-bar [nextPageValid]="ergonomicsForm.valid" (nextPage)="nextPage()" [lastPage]="'home'"></app-bottom-bar>
</ng-container>
