/**
 * Flag for enabling/disabling auto login
 * use this flag during development to disable auto login
 */
export const disableAutoLogin = localStorage.getItem('ttl_disableAutoLogin') === 'true';

/**
 * Flag for enabling/disabling installation screen
 * use this flag during development to disable installation screen
 */
export const disableInstallationScreen = localStorage.getItem('ttl_disableInstallationScreen') === 'true';

/**
 * Flag for enabling/disabling visual debug mode
 */
export const enableOutline = localStorage.getItem('ttl_enableOutline') === 'true';

/**
 * Minimum log level for the application
 * use this flag during development to set the minimum log level
 * 
 * Possible values:
 * - 'debug'
 * - 'info'
 * - 'warning'
 * - 'error'
 * - 'fatal'
 * 
 * If no value is set, all log levels will be logged
 */
export const minLogLevel = localStorage.getItem('ttl_logLevel');
