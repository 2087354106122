import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "@services/api.service";
import {Centration} from "@typings/measurement.type";
import { TranslateService } from '@ngx-translate/core';
import { stepFactory } from '@configs/step-indicator.config';
import { IndicatorSteps } from '@typings/steps.type';
import { Logger } from '@utils/logging.utils';
import { TTLValidator } from 'src/app/validators/ttl.validator';
import { parseLocalFloat } from '@utils/validation.utils';

@Component({
  selector: 'app-centration',
  templateUrl: './centration.component.html',
  styleUrls: ['./centration.component.css']
})
export class CentrationComponent implements OnInit {

  private readonly DEFAULT_BVD = 12;

  /**
   * Step Factory for the Step Indicator containing the steps
   */
  public steps = stepFactory(this._translate);
  
  /**
   * Internal Form Component for Reactivity Forms Module
   */
  public centrationForm = new FormGroup(({
    pupilDistanceR: new FormControl<number | null | string>(null, [
      Validators.required,
      TTLValidator({steps: 0.1, min: 20.0, max: 40.0})
    ]),
    pupilHeightR: new FormControl<number | null | string>(null, [
      Validators.required,
      TTLValidator({steps: 0.1, min: 10.0, max: 45.0})
    ]),
    pupilBVDR: new FormControl<number | null | string>(12.00, [
      TTLValidator({steps: 0.1, min: 5.0, max: 20.0, optional: true})
    ]),
    pupilDistanceL: new FormControl<number | null | string>(null, [
      Validators.required,
      TTLValidator({steps: 0.1, min: 20.0, max: 40.0})
    ]),
    pupilHeightL: new FormControl<number | null | string>(null, [
      Validators.required,
      TTLValidator({steps: 0.1, min: 10.0, max: 45.0})
    ]),
    pupilBVDL: new FormControl<number | null | string>(12.00, [
      TTLValidator({steps: 0.1, min: 5.0, max: 20.0, optional: true})
    ]),
  }))

  /**
   * Internal DTO for Centration
   */
  private _centrationObject: Centration = {
    pupilDistance: {
      right: 0,
      left: 0
    },
    pupilHeight: {
      right: 0,
      left: 0,
    },
    bvd: {
      right: 0,
      left: 0
    }
  }


  constructor(
    private readonly _router: Router, 
    private readonly _apiService: ApiService, 
    private readonly _translate: TranslateService
  ) {}

  ngOnInit() {
    this.centrationForm.patchValue({
      pupilDistanceR: this._apiService.measurementFactory.getCentration()?.pupilDistance.right.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
      pupilHeightR: this._apiService.measurementFactory.getCentration()?.pupilHeight.right.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
      pupilBVDR: this._apiService.measurementFactory.getCentration()?.bvd.right.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}) ?? this.DEFAULT_BVD.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
      pupilDistanceL: this._apiService.measurementFactory.getCentration()?.pupilDistance.left.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
      pupilHeightL: this._apiService.measurementFactory.getCentration()?.pupilHeight.left.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
      pupilBVDL: this._apiService.measurementFactory.getCentration()?.bvd.left.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2}) ?? this.DEFAULT_BVD.toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    })
  }

  public fieldComponentChanged(target: 'bvd_r' | 'bvd_l', newValue: number|string) {

    if(typeof(newValue) !== 'string' && typeof(newValue) !== 'number') return;    
    const num = parseFloat(newValue.toString().replace(',', '.')).toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    if(target === 'bvd_r') this.centrationForm.controls.pupilBVDL.setValue(num);
    if(target === 'bvd_l') this.centrationForm.controls.pupilBVDR.setValue(num);
  }

  /**
   * Interaction with the Step Indicator for navigating to other steps
   * Only navigates to next steps when the current step is valid
   * Always navigates to previous steps
   * @param event Contain the target of the step indicator
   */
  public async interactWithStepIndicator(event: {target: IndicatorSteps}) {
    const current = this.steps.findIndex(v => v.value === 'centration');
    const target = this.steps.findIndex(v => v.value === event.target);
    
    const msg = `Step Indicator Interaction for ${event.target} is ${current >= target || (current < target && this.centrationForm.valid) ? 'valid' : 'invalid'}`;
    Logger.debug(msg);
    
    if (current >= target || (current < target && this.centrationForm.valid)){
      this.save();
      await this._router.navigate([event.target]);
    }
  }

  /**
   * Save the current form values to the measurement factory
   */
  private save() {
    this._centrationObject.pupilDistance = {
      right: parseLocalFloat(this.centrationForm.controls.pupilDistanceR.value!.toString()),
      left: parseLocalFloat(this.centrationForm.controls.pupilDistanceL.value!.toString())
    }

    this._centrationObject.pupilHeight = {
      right: parseLocalFloat(this.centrationForm.controls.pupilHeightR.value!.toString()),
      left: parseLocalFloat(this.centrationForm.controls.pupilHeightL.value!.toString())
    }

    this._centrationObject.bvd = {
      right: parseLocalFloat(this.centrationForm.controls.pupilBVDR.value!.toString()),
      left: parseLocalFloat(this.centrationForm.controls.pupilBVDL.value!.toString())
    }
    
    this._apiService.measurementFactory.addCentration(this._centrationObject);
  }

  /**
   * Navigate to the next page
   */
  public nextPage(): void {
    this.save();
    this._router.navigate(['/refraction'])
  }

  /**
   * Navigate to the previous page
   */
  public previousPage(): void {
    this._router.navigate(['/ergonomics'])
  }

}
