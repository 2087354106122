import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.css']
})
export class BottomBarComponent {
  @Output("nextPage") nextPage: EventEmitter<any> = new EventEmitter<any>();
  @Output("previousPage") previousPage: EventEmitter<any> = new EventEmitter<any>();
  @Output("sendData") sendData: EventEmitter<any> = new EventEmitter<any>();
  @Input() nextPageValid: boolean = true;
  @Input() lastPage: string = '';

  constructor(
    public router: Router
  ) {
  }

  public buttonClick(): void {
    this.nextPage.emit();
  }
  public previousClick(): void {
    this.previousPage.emit();
  }

  public sendDataClick(): void {
    this.sendData.emit();
  }
}
