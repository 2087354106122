// Core Modules
import {NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Modules
import {SharedModule} from "./shared/shared.module";
import { NgZeissIdModule, ZeissIdTokenInterceptor } from '@planbgmbh/zeiss-id-angular-library';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Interceptors
import { RequestInterceptor } from '@interceptors/request.interceptor';


// Components
import { AppComponent } from '@components/app/app.component';
import {HomeComponent} from "@components/home/home.component";
import {ErgonomicsComponent} from "@components/ergonomics/ergonomics.component";
import { CentrationComponent } from '@components/centration/centration.component';
import { SummaryComponent } from '@components/summary/summary.component';
import { RefractionComponent } from '@components/refraction/refraction.component';

// ZUI
import '@zeiss/zui';
import '@zeiss/zui-icons';
import { registerTheme, setAppTheme, themeZbdsBase, themeZbdsDark, themeZbdsLight } from '@zeiss/zui-themes';

// Configs
import { ZEISSID_CONFIG } from '@configs/zeiss-id.config';

// Loader
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SubscriptionKeyInterceptor } from '@interceptors/subscription-key.interceptor';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerTheme(themeZbdsBase);
registerTheme(themeZbdsLight);
registerTheme(themeZbdsDark);

setAppTheme('zbds-light');

@NgModule({
  declarations: [AppComponent, HomeComponent, ErgonomicsComponent, SummaryComponent, CentrationComponent, RefractionComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgZeissIdModule.forRoot(ZEISSID_CONFIG),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FormsModule,
    ReactiveFormsModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ZeissIdTokenInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: SubscriptionKeyInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
