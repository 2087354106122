import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { OfflineService } from '@services/offline.service';
import { NetworkStatus } from '@typings/network-status.enum';
import { Measurement } from '@typings/measurement.type';
import { Logger } from '@utils/logging.utils';
import { environment } from '@environments/environment';
import { MeasurementDTO } from '@typings/measurement.dto.type';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private readonly _offline: OfflineService) {}

  /**
   * Intercept HTTP Requests and bypass the cache when the network is online
   * Handle cache logic when the network is offline
   * @param request HTTP Request
   * @param next HTTP Handler
   * @returns 
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(!request.url.startsWith(environment.apiBaseUrl)) {
      Logger.debug(`[Cache] Skip ${request.url} according to whitelist`);
      return next.handle(request);
    }
    
    // Guard Condition when the request is not a POST request -> bypass the cache
    if(request.method !== 'POST') { 
      Logger.debug(`[Cache] Skip ${request.url} according to request-method whitelist`);
      return next.handle(request);
    }
    
    // Guard Condition when the network is online -> bypass the cache
    if(this._offline.networkState$.getValue() === NetworkStatus.Online) {
      Logger.debug(`[Cache] Skip ${request.url} according to network status`);
      return next.handle(request);
    }

    // Otherwise handle cache logic and return an empty observable
    const body = request.body;

    if(body) {
      Logger.info(`[Cache] Adding ${request.url} to cache...`, body as MeasurementDTO);
      this._offline.cache.add(body as MeasurementDTO);
      this._offline.cacheState$.next('Pending');
    }
    
    return EMPTY;
  }
}
