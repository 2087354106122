import { Centration, Ergonomics, Measurement, Refraction } from "@typings/measurement.type";
import { IFactory, Add, Optional } from "./factory.utils";
import { MeasurementDTO } from "@typings/measurement.dto.type";
import { DateTime } from "luxon";

/**
 * MeasurementFactory is a helper class for creating a Measurement object
 */
export class MeasurementFactory implements IFactory<Measurement> {
    id?: string;
    ergonomics?: Ergonomics;
    refraction?: Refraction;
    centration?: Centration;

    getId: () => string | undefined = () => this.id;
    getErgonomics: () => Ergonomics | undefined = () => this.ergonomics;
    getRefraction: () => Refraction | undefined = () => this.refraction;
    getCentration: () => Centration | undefined = () => this.centration;
    
    addId: (value: string) => Add<Measurement> = (value) => {
        this.id = value;
        return this;
    }

    addErgonomics: (value: Ergonomics) => Add<Measurement> = (value) => {
        this.ergonomics = value;
        return this;
    }

    addRefraction: (value: Refraction) => Add<Measurement> = (value) => {
        this.refraction = value;
        return this;
    }

    addCentration: (value: Centration) => Add<Measurement> = (value) => {
        this.centration = value;
        return this;
    }

    build: () => Optional<Measurement> = () => {
        return {
            id: this.id,
            ergonomics: this.ergonomics,
            refraction: this.refraction,
            centration: this.centration
        }
    }

    getCurrentDateTimeISOWithTZ():string {     
        return DateTime.now().toISO() ?? "";
    }

    toFloat(value: any): number | undefined {
        return value ? parseFloat(value.toString().replace(',', '.')) : undefined;
    }
    
    toDTO: () => Optional<MeasurementDTO> = () => {

        return {
            createdAt: this.getCurrentDateTimeISOWithTZ(),
            T1030_BIOD_BVD_CD_CL_L: this.toFloat(this.centration?.bvd.left),
            T1030_BIOD_BVD_CD_CL_R: this.toFloat(this.centration?.bvd.right),
            T1030_BIOD_PD_CD_CL_L: this.toFloat(this.centration?.pupilDistance.left),
            T1030_BIOD_PD_CD_CL_R: this.toFloat(this.centration?.pupilDistance.right),
            T1030_BIOD_PH_CD_CL_L: this.toFloat(this.centration?.pupilHeight.left),
            T1030_BIOD_PH_CD_CL_R: this.toFloat(this.centration?.pupilHeight.right),
            T1030_BIOD_REF_CL_L_AXE: this.toFloat(this.refraction?.carrierLense?.axis.left),
            T1030_BIOD_REF_CL_L_CYL: this.toFloat(this.refraction?.carrierLense?.cylinder.left),
            T1030_BIOD_REF_CL_L_SPH: this.toFloat(this.refraction?.carrierLense?.sphere.left),
            T1030_BIOD_REF_CL_R_AXE: this.toFloat(this.refraction?.carrierLense?.axis.right),
            T1030_BIOD_REF_CL_R_CYL: this.toFloat(this.refraction?.carrierLense?.cylinder.right),
            T1030_BIOD_REF_CL_R_SPH: this.toFloat(this.refraction?.carrierLense?.sphere.right),
            T1030_BIOD_REF_OL_L_AXE: this.toFloat(this.refraction?.ophthalmicLense?.axis.left),
            T1030_BIOD_REF_OL_L_CYL: this.toFloat(this.refraction?.ophthalmicLense?.cylinder.left),
            T1030_BIOD_REF_OL_L_SPH: this.toFloat(this.refraction?.ophthalmicLense?.sphere.left),
            T1030_BIOD_REF_OL_R_AXE: this.toFloat(this.refraction?.ophthalmicLense?.axis.right),
            T1030_BIOD_REF_OL_R_CYL: this.toFloat(this.refraction?.ophthalmicLense?.cylinder.right),
            T1030_BIOD_REF_OL_R_SPH: this.toFloat(this.refraction?.ophthalmicLense?.sphere.right),
            T1030_BIOD_DAN_OS: this.toFloat(this.ergonomics?.declinationAngle),
            H1030_BIOD_APP_WD: this.toFloat(this.ergonomics?.workingDistance),
            H1030_BIOD_APP_ID: this.id
        }
    }

    fromDTO: (dto: MeasurementDTO) => Add<Measurement> = (dto) => {
        this.addId(dto.H1030_BIOD_APP_ID);
        this.addErgonomics({
            declinationAngle: dto.T1030_BIOD_DAN_OS,
            workingDistance: dto.H1030_BIOD_APP_WD
        });
        this.addRefraction({
            carrierLense: {
                axis: {
                    left: dto.T1030_BIOD_REF_CL_L_AXE,
                    right: dto.T1030_BIOD_REF_CL_R_AXE
                },
                cylinder: {
                    left: dto.T1030_BIOD_REF_CL_L_CYL,
                    right: dto.T1030_BIOD_REF_CL_R_CYL
                },
                sphere: {
                    left: dto.T1030_BIOD_REF_CL_L_SPH,
                    right: dto.T1030_BIOD_REF_CL_R_SPH
                }
            },
            ophthalmicLense: {
                axis: {
                    left: dto.T1030_BIOD_REF_OL_L_AXE,
                    right: dto.T1030_BIOD_REF_OL_R_AXE
                },
                cylinder: {
                    left: dto.T1030_BIOD_REF_OL_L_CYL,
                    right: dto.T1030_BIOD_REF_OL_R_CYL
                },
                sphere: {
                    left: dto.T1030_BIOD_REF_OL_L_SPH,
                    right: dto.T1030_BIOD_REF_OL_R_SPH
                }
            }
        });
        this.addCentration({
            bvd: {
                left: dto.T1030_BIOD_BVD_CD_CL_L,
                right: dto.T1030_BIOD_BVD_CD_CL_R
            },
            pupilDistance: {
                left: dto.T1030_BIOD_PD_CD_CL_L,
                right: dto.T1030_BIOD_PD_CD_CL_R
            },
            pupilHeight: {
                left: dto.T1030_BIOD_PH_CD_CL_L,
                right: dto.T1030_BIOD_PH_CD_CL_R
            }
        });

        return this;
    }
}