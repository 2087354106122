<ng-container *ngIf="initials;else unauthorized">
  <zui-headerbar class="bg-headerbar">
    <zui-zeiss-logo size="s48" slot="icon"></zui-zeiss-logo>

    <div slot="iconButtons" class="flex gap-8">
      <!-- Connection Indicator -->
      <zui-icon-med-cloud-services-error *ngIf="offline" (click)="toggleOfflinePopover()" size="s24" class="text-white"></zui-icon-med-cloud-services-error>
      
      <!-- Update Indicator -->
      <zui-icon-common-refresh *ngIf="updateAvailable" size="s24" class="text-white" (click)="triggerUpdate()"></zui-icon-common-refresh>
      
      <!-- Sync State Indicators -->
      <div class="flex" (click)="toggleSyncPopover()">
        <zui-icon-common-refresh size="s24" class="text-white"></zui-icon-common-refresh>
        <zui-icon-alerts-ewi-warning-triangle *ngIf="(cacheState) === 'Pending' || cacheState === 'Synchronizing'" size="s16" class="-translate-y-1/2"></zui-icon-alerts-ewi-warning-triangle>
        <zui-icon-ewi-ewi-colored-dark-check *ngIf="(cacheState) === 'Done'" size="s16" class="-translate-y-1/2"></zui-icon-ewi-ewi-colored-dark-check>
      </div>
    </div>

    <!-- Avatar -->
    <zui-user-menu-button size="l" slot="userMenuButton" (click)="toggleAvatarMenuPopover()">
      <zui-avatar [initials]="initials" slot="avatar"></zui-avatar>
    </zui-user-menu-button>
  </zui-headerbar>

  <!-- Sync State Popover -->
  <app-popover title="Synchronization" (backdropClick)="closePopovers()" type="sync" *ngIf="syncPopoverOpen">
    <!-- Done Icon -->
    <div class="flex flex-col mt-5" *ngIf="(cacheState) === 'Done'">
      <div class="w-full flex justify-center">
        <zui-icon-ewi-ewi-colored-dark-check size="s32"></zui-icon-ewi-ewi-colored-dark-check>
      </div>
      <p class="text-xs font-semibold text-[#778592] text-center mt-4">
        {{'components.syncDialog.synced'|translate}}
      </p>
    </div>
    
    <!-- Pending Icon -->
    <div class="flex flex-col mt-5" *ngIf="(cacheState) === 'Pending' || (cacheState) === 'Synchronizing'">
      <div class="w-full flex justify-center">
        <zui-icon-alerts-ewi-warning-triangle size="s32"></zui-icon-alerts-ewi-warning-triangle>
      </div>
      <p class="text-xs font-semibold text-[#778592] text-center mt-4">
        <span *ngIf="applicationCache.length <= 1">{{'components.syncDialog.pending_online_single'|translate}}</span>
        <span *ngIf="applicationCache.length > 1">{{'components.syncDialog.pending_online_multiple'|translate:{count:applicationCache.length} }}</span>
      </p>
    </div>

    <!-- Pending Items List -->
    <div class="flex flex-col mt-6">
      <div class="w-full" *ngIf="!offline">
        <div class="w-full" *ngFor="let item of applicationCache.cache">
          <div class="flex justify-between items-center py-2">
            <p class="text-sm text-[#32373E] truncate">{{item.H1030_BIOD_APP_ID}}</p>
            <zui-icon-alerts-ewi-warning-triangle *ngIf="item.state === 'cached'" class="grayscale invert" size="s16"></zui-icon-alerts-ewi-warning-triangle>
            <zui-icon-common-refresh size="s16" *ngIf="item.state === 'pending'" class="animate-spin"></zui-icon-common-refresh>
            <zui-icon-symbols-checkmark *ngIf="item.state === 'done'" class="text-[#2bbf91]" size="s16"></zui-icon-symbols-checkmark>
          </div>
          <zui-divider></zui-divider>
        </div>
      </div>
    </div>

    <!-- Offline Message for Pending Items -->
    <div class="flex flex-col" *ngIf="cacheState === 'Pending' && offline">
      <p class="text-xs font-semibold text-[#778592] text-center">
        {{'components.syncDialog.pending_offline'|translate}}
      </p>
    </div>

    <!-- Sync Button -->
    <div class="flex justify-center mt-6" *ngIf="(cacheState) !== 'Done' && !offline">
      <zui-button style="--zui-button-width: 100%;" size="l" (click)="triggerSync()" [disabled]="(cacheState) === 'Synchronizing'">
        <zui-icon-common-refresh slot="icon"></zui-icon-common-refresh>
        {{'components.syncDialog.button'|translate}}
      </zui-button>
    </div>
  </app-popover>

  <!-- Offline Indicator Popover -->
  <app-popover title="No internet connection" type="offline" *ngIf="offlinePopoverOpen" (backdropClick)="closePopovers()">
    <div class="flex flex-col mt-5">
      <div class="w-full flex justify-center">
        <zui-icon-ewi-ewi-colored-dark-error-round size="s32"></zui-icon-ewi-ewi-colored-dark-error-round>
      </div>
      <p class="text-xs font-semibold text-[#778592] text-center mt-4">
        {{'components.syncDialog.offline'|translate}}
      </p>
    </div>
    </app-popover>

  <!-- Avatar Menu Popover -->
  <app-popover type="avatar_menu" *ngIf="avatarMenuPopoverOpen" (backdropClick)="closePopovers()">
    <!-- Item List -->
    <div class="flex flex-col">
      <!-- Item -->
      <div class="w-full py-4 cursor-pointer">
        <a class="px-2 font-normal text-sm" [href]="legal.privacyNotice" target="_blank">{{'components.avatarMenu.privacy'|translate}}</a>
      </div>
      <!-- Item -->
      <div class="w-full py-4 cursor-pointer">
        <a class="px-2 font-normal text-sm" [href]="legal.publisher" target="_blank">{{'components.avatarMenu.publisher'|translate}}</a>
      </div>
      <!-- Item -->
      <div class="w-full py-4 cursor-pointer">
        <a class="px-2 font-normal text-sm" [href]="legal.legalNotice" target="_blank">{{'components.avatarMenu.legal'|translate}}</a>
      </div>
      <!-- Item -->
      <div class="w-full py-4 cursor-pointer">
        <a class="px-2 font-normal text-sm" [href]="legal.contact" target="_blank">{{'components.avatarMenu.contact'|translate}}</a>
      </div>
      <!-- Item -->
      <div class="w-full py-4 cursor-pointer" (click)="logout.emit()">
        <p class="px-2 font-normal text-sm">{{'components.avatarMenu.logout'|translate}}</p>
      </div>
    </div>
  </app-popover>

</ng-container>

<ng-template #unauthorized>
  <zui-headerbar class="bg-headerbar">
    <zui-zeiss-logo size="s48" slot="icon"></zui-zeiss-logo>
    <zui-headerbar-product-name slot="productName"></zui-headerbar-product-name>
  </zui-headerbar>
</ng-template>
