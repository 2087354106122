import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Logger } from '@utils/logging.utils';

@Injectable()
export class SubscriptionKeyInterceptor implements HttpInterceptor {

  constructor() {}

  /**
   * Intercept HTTP Requests and bypass the cache when the network is online
   * Handle cache logic when the network is offline
   * @param request HTTP Request
   * @param next HTTP Handler
   * @returns 
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.startsWith(environment.apiBaseUrl)) {
      Logger.debug('[Subscription Key] Intercept according to whitelist...');
      const headerName = environment.apiSubscriptionKeyHeader;
      const clone = request.clone({setHeaders: {
        [headerName]: environment.apiSubscriptionKey
      }});

      Logger.debug(`[Subscription Key] Added Subscription Key: ${environment.apiSubscriptionKey}`, clone);

      return next.handle(clone);
    }

    return next.handle(request);
  }
}
