import { Pipe, PipeTransform } from '@angular/core';
import { IndicatorItem } from '@typings/step-indicator-item.type';
import { IndicatorSteps } from '@typings/steps.type';

@Pipe({
    name: 'previous'
})

export class PreviousPipe implements PipeTransform {
    transform(value: IndicatorSteps, ...args: [steps: IndicatorItem[], current: IndicatorSteps]): any {
        return args[0].findIndex(v => v.value === value) <= args[0].findIndex(v => v.value === args[1]);
    }
}