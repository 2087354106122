import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Ergonomics} from "@typings/measurement.type";
import {ApiService} from "@services/api.service";
import {Router} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { stepFactory } from '@configs/step-indicator.config';
import { IndicatorSteps } from '@typings/steps.type';
import { Logger } from '@utils/logging.utils';
import { console } from '@utils/rxjs.utils';
import { LogLevel } from '@typings/log-level.enum';
import { TTLValidator } from 'src/app/validators/ttl.validator';
import { parseLocalFloat } from '@utils/validation.utils';

@Component({
  selector: 'app-ergonomics',
  templateUrl: './ergonomics.component.html',
  styleUrls: ['./ergonomics.component.css']
})
export class ErgonomicsComponent implements OnInit {

  /**
   * Step Factory for the Step Indicator containing the steps
   */
  public steps = stepFactory(this._translate);

  /**
   * Internal Form Component for Reactivity Forms Module
   */
  public ergonomicsForm = new FormGroup({
    distanceEyeTeeth: new FormControl<number | null>(null, [
      TTLValidator({steps: 10})
    ]),
    angleOfView: new FormControl<number | null | string>(20.00, [
      Validators.required,
      TTLValidator({steps: 5, min: 0, max: 30})
    ]),
  });

  /**
   * Internal DTO for Ergonomics
   */
  private _ergonomicsObject: Ergonomics = {
    declinationAngle: 0,
    workingDistance: 0
  }

  constructor(
    private readonly _apiService: ApiService, 
    private readonly _router: Router, 
    private readonly _translate: TranslateService
    ) {}

  ngOnInit() {
    this.ergonomicsForm.patchValue({
      distanceEyeTeeth: this._apiService.measurementFactory.getErgonomics()?.workingDistance,
      angleOfView: this._apiService.measurementFactory.getErgonomics()?.declinationAngle.toLocaleString(navigator.language, {maximumFractionDigits: 2, minimumFractionDigits: 2}) ?? (20.00).toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    })

    this.ergonomicsForm.valueChanges.pipe(console('Value Change: ', LogLevel.Debug)).subscribe();
  }

  /**
   * Interaction with the Step Indicator for navigating to other steps
   * Only navigates to next steps when the current step is valid
   * Always navigates to previous steps
   * @param event Contain the target of the step indicator
   */
  public async interactWithStepIndicator(event: {target: IndicatorSteps}) {
    const current = this.steps.findIndex(v => v.value === 'ergonomics');
    const target = this.steps.findIndex(v => v.value === event.target);
    
    const msg = `Step Indicator Interaction for ${event.target} is ${current >= target || (current < target && this.ergonomicsForm.valid) ? 'valid' : 'invalid'}`;
    Logger.debug(msg);
    
    if (current >= target || (current < target && this.ergonomicsForm.valid)){
      this.save();
      await this._router.navigate([event.target]);
    }
  }

  /**
   * Save the current form values to the measurement factory
   */
  private save() {
    if (this.ergonomicsForm.controls.distanceEyeTeeth.value) {
      this._ergonomicsObject.workingDistance = this.ergonomicsForm.controls.distanceEyeTeeth.value;
    }

    if (this.ergonomicsForm.controls.angleOfView.value) {
      this._ergonomicsObject.declinationAngle = parseLocalFloat(this.ergonomicsForm.controls.angleOfView.value!.toString());
    }

    this._apiService.measurementFactory.addErgonomics(this._ergonomicsObject);
  }

  /**
   * Navigate to the next page
   */
  public nextPage(): void {
    this.save();
    this._router.navigate(['/centration']);
  }
}
