<div class="flex flex-col items-end">
  <div 
    class="relative flex items-center justify-end border-b-1 focus:border-b-black gap-2" 
    [ngClass]="{
      'w-full': full, 'w-20': !full, 
      'border-b-red text-red': control.touched && !control.valid, 
      'border-b-zeiss-grey': !control.touched || control.valid,
    }">
    <input 
      type="text"
      autocomplete="off"
      inputmode="numeric" 
      style="outline: none; text-align: right;"
      class="peer rounded-none h-12 outline-none focus:text-black"
      [formControl]="control" 
      [value]="control.value"
      (change)="change.emit($any($event.target).value)"
      (blur)="onBlur($any($event.target).value)"
      [ngClass]="{
        ' text-red text-right': control.touched && !control.valid, 
        'w-20 text-right': !full,
        'w-full': full
      }"
    />
    <p class="peer-focus:text-black" [ngClass]="{'text-red': control.touched && !control.valid}">{{unit}}</p>
  </div>

  <!-- Hint & Error -->
  <p 
  class="text-xxs text-right" 
  [ngClass]="{
    'text-zeiss-grey': !control.touched || control.valid || control.disabled,
    'text-red': control.touched && !control.valid && !control.disabled
  }">
    <ng-content></ng-content>
  </p>
</div>
