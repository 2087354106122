import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css']
})
export class PopoverComponent {

  @Input() public title?: string;

  /**
   * Type of the popover
   * Can be 'offline' or 'sync'
   */
  @Input() public type!: 'offline' | 'sync' | 'avatar_menu';

  @Output() public backdropClick: EventEmitter<void> = new EventEmitter<void>();
}
