import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-value-box',
  templateUrl: './value-box.component.html',
  styleUrls: ['./value-box.component.css']
})
export class ValueBoxComponent {

  @Input() prefix: string = '';
  @Input() unit: string = '';

}
