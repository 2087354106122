import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from "@services/api.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Logger} from "@utils/logging.utils";
import { TranslateService } from '@ngx-translate/core';
import { stepFactory } from '@configs/step-indicator.config';
import { IndicatorSteps } from '@typings/steps.type';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent {

  public steps = stepFactory(this._translate);

  public dialogOpen: boolean = false;

  public loading: boolean = false;

  public dataSend: boolean = false;

  public summaryFormGroup = new FormGroup({
    customerId: new FormControl<string>('', [
      Validators.maxLength(30),
      Validators.required
    ])
  })

  constructor(
    public readonly _apiService: ApiService,
    private readonly _router: Router,
    private readonly _translate: TranslateService
  ) {
    if(this._apiService.measurementFactory.ergonomics?.declinationAngle == null) {
      this._router.navigate(['/home']);
    }
  }

  public async interactWithStepIndicator(event: {target: IndicatorSteps}) {
    const current = this.steps.findIndex(v => v.value === 'summary');
    const target = this.steps.findIndex(v => v.value === event.target);

    const msg = `Step Indicator Interaction for ${event.target} is ${current >= target || (current < target && this.summaryFormGroup.valid) ? 'valid' : 'invalid'}`;
    Logger.debug(msg);

    if (current >= target || (current < target && this.summaryFormGroup.valid)){
      await this._router.navigate([event.target]);
    }
  }

  public openDialog(): void {
    this.dialogOpen = true;
  }

  public closeDialog(): void {
    this.dialogOpen = false;
  }

  public async sendData() {
    this.loading = true;
    if (this.summaryFormGroup.controls.customerId.value) {
      this._apiService.measurementFactory.addId(this.summaryFormGroup.controls.customerId.value)
    }

    const result = await this._apiService.saveMeasurement();
    if(result.ok || result.intercepted) {
      setTimeout(() => {
        this.loading = false;
        this.dataSend = true;
      }, Math.random() * 1000);

      setTimeout(() => {

      })
    } else {
      Logger.error('Error while sending data', result.result.error);
      this.loading = false;
    }
  }

  public async closeSuccess() {
    await this._router.navigate(['/home']);
  }

  public async previousPage(): Promise<void> {
    await this._router.navigate(['/refraction'])
  }

}
