import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MeasurementFactory} from '@utils/measurement.factory';
import {firstValueFrom} from 'rxjs';
import {environment} from "@environments/environment";
import { Logger } from '@utils/logging.utils';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _http: HttpClient) {
  }

  /**
   * MeasurementFactory for creating Measurements
   *
   */
  private _measurementFactory: MeasurementFactory = new MeasurementFactory();

  public get measurementFactory() {
    return this._measurementFactory;
  }

  public initializeMeasurementFactory() {
    Logger.debug('Initialize new Measurement Factory');
    this._measurementFactory = new MeasurementFactory();
  }

  /**
   * Saves a Measurement to the backend
   * @returns the Response Object or the Response Error or null if intercepted
   */
  public async saveMeasurement(dummyData?: Object): Promise<{ok: boolean, result: any, intercepted: boolean}> {
    const measurement = dummyData ?? this._measurementFactory.toDTO();
    const url = `${environment.apiBaseUrl}/opticsrecords`;
    
    try {
      const result = await firstValueFrom(this._http.post(url, measurement));
      return {ok: true, result: result, intercepted: false};
    } catch(error) {

      if(error instanceof HttpErrorResponse) {
        Logger.error(error.message, error);
        return {ok: false, result: error, intercepted: false};
      }

      if(error instanceof Error) {
        Logger.info(`Request was intercepted from Request Interceptor`);
      }

      return {ok: true, result: null, intercepted: true};
    }
  }
}
