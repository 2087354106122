import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";
import { Logger } from "@utils/logging.utils";
import { generateCorrectionFactor, isNullOrEmtpy, isNumber } from "@utils/validation.utils";

/**
 * TTLValidator for checking if the entered number is compatible with the TTL format
 * @param options options for the validator
 * @param options.steps steps for the validator
 * @param options.min minimum value for the validator
 * @param options.max maximum value for the validator
 * @param options.optional optional flag for the validator
 * @returns ValidatorFn
 */
export function TTLValidator(options?: {steps?: number, min?: number, max?: number, optional?: boolean}) {
    return (control: AbstractControl): ValidationErrors | null => {
        // original value from control
        const {value } = control;
        Logger.debug(`[TTLValidator] value: ${value} [${typeof value}]`);

        const nullCheck = isNullOrEmtpy(value);
        Logger.debug(`[TTLValidator] isNullOrEmpty: ${nullCheck}`);
        
        /** Skip all checks as long as there is no value provided */
        if(options?.optional && nullCheck) return null;

        if(nullCheck) return { required: true };

        const numberCheck = isNumber(value);
        Logger.debug(`[TTLValidator] isNumber: ${numberCheck}`);
        if(!numberCheck) return { NaN: true };

        const number = parseFloat(value.toString().replace(',', '.'));
        Logger.debug(`[TTLValidator] parsed number: ${number} [${typeof number}]`);

        /** Min/Max Validation */
        Logger.debug(`[TTLValidator] minimum check (${options?.min} && ${number} < ${options?.min})`);
        if(options?.min !== undefined && number < options.min) return { min: {min: options.min, current: number} };

        Logger.debug(`[TTLValidator] maximum check (${options?.max} && ${number} > ${options?.max})`);
        if(options?.max !== undefined && number > options.max) return { max: {max: options.max, current: number} };

        /** Step Calculation Validation */
        const steps = options?.steps ?? 1;

        const correctionFactor = generateCorrectionFactor(number);
        Logger.debug(`[TTLValidator] correction factor: ${correctionFactor} [${typeof correctionFactor}]`);

        const normalized = Math.round(number * correctionFactor);
        Logger.debug(`[TTLValidator] normalized: ${normalized} [${typeof normalized}]`);

        const normalizedSteps = Math.round(steps * correctionFactor);
        Logger.debug(`[TTLValidator] normalized steps: ${normalizedSteps} [${typeof normalizedSteps}]`);

        const calc = normalized % normalizedSteps;
        Logger.debug(`[TTLValidator] calc (${normalized}%${normalizedSteps}): ${calc} [${typeof calc}]`);

        if(calc !== 0) return { step: { steps: steps, current: number } };

        Logger.debug(`[TTLValidator] valid`);
        return null;
    };
}