import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { CacheStatus } from '@typings/cache-status.enum';
import { MeasurementDTO } from '@typings/measurement.dto.type';
import { ApplicationCache } from '@utils/cache.utils';
import { Logger } from '@utils/logging.utils';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  /**
   * Save the cache state before unloading the page
   * @param event UnloadEvent Object
   */
  @HostListener('window:beforeunload', ['$event'])
  public onBeforeUnload(event: Event): void {
    Logger.debug('Detected Unload Event -> Save Cache Sate');
    this.saveCache();
  }

  /**
   * Combination of firstname + lastname of the user
   */
  @Input() public username?: string | null;

  /**
   * Flag for displaying the update available badge
   */
  @Input() public updateAvailable: boolean = false;

  /**
   * Flag for displaying the offline badge
   */
  @Input() public offline: boolean = false;

  /**
   * Flag for displaying the cache badge
   */
  @Input() public cacheState!: CacheStatus;

  /**
   * Cache for Measurements
   */
  @Input('cache') public applicationCache!: ApplicationCache<MeasurementDTO>;

  /**
   * Logout Event trigger when user is clicking on the logout button
   */
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Update Event trigger when user is clicking on the update indicator
   */
  @Output() update: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Language Change Event trigger when user is clicking on the language indicator
   */
  @Output() languageChange: EventEmitter<void> = new EventEmitter<void>();

  @Output() synchronize: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Returns the initials of the user if the user is defined, otherwise null
   */
  public get initials(): string | null {
    return this.username?.split(' ').map((n) => n[0]).join('') ?? null;
  }

  public legal: { privacyNotice: string; legalNotice: string; publisher: string; contact: string; } = environment.legal;

  public syncPopoverOpen: boolean = false;

  public offlinePopoverOpen: boolean = false;

  public avatarMenuPopoverOpen: boolean = false;

  public toggleSyncPopover(): void {
    if(!this.syncPopoverOpen) this.saveCache();
    this.syncPopoverOpen = !this.syncPopoverOpen;
  }

  public toggleOfflinePopover(): void {
    this.offlinePopoverOpen = !this.offlinePopoverOpen;
  }

  public toggleAvatarMenuPopover(): void {
    this.avatarMenuPopoverOpen = !this.avatarMenuPopoverOpen;
  }

  public closePopovers(): void {
    this.syncPopoverOpen = false;
    this.offlinePopoverOpen = false;
    this.avatarMenuPopoverOpen = false;

    this.saveCache();
  }

  /**
   * Trigger the Update Event
   */
  public triggerUpdate(): void {
    this.update.emit();
  }

  /**
   * Trigger the Language Change Event
   */
  public triggerLanguageDialog(): void {
    this.languageChange.emit();
  }

  public triggerSync(): void {
    this.synchronize.emit();
  }

  public saveCache(): void {
    for(const item of this.applicationCache.cache) {
      if(item.state === 'cached') continue;
      this.applicationCache.remove(item);
    }
  }

}
