import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { ZeissAuthGuard } from '@planbgmbh/zeiss-id-angular-library';
import { OfflineService } from '@services/offline.service';
import { Logger } from '@utils/logging.utils';

export const AuthenticationProxyGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ) => {
    const authGuard = inject(ZeissAuthGuard);
    const offlineService = inject(OfflineService);

    const refreshTokenExpiration = parseInt(localStorage.getItem('expires_at') ?? '0');
    const idTokenExpiration = parseInt(localStorage.getItem('id_token_expires_at') ?? '0');

    const expired = refreshTokenExpiration < Date.now() && idTokenExpiration < Date.now();
    Logger.info(`AuthenticationProxyGuard: tokens are ${expired ? 'expired' : 'not expired'}`)

    /** Bypass Application Level Security in case that the user is offline. */
    if(offlineService.networkState$.getValue() === 'offline') return true;
    return authGuard.canActivate(route, state);
}