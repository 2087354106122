import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { ZeissIdService } from '@planbgmbh/zeiss-id-angular-library';
import { ApiService } from '@services/api.service';
import { disableInstallationScreen } from '@utils/debug.utils';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  public disableInstallationScreen: boolean = disableInstallationScreen;

  constructor(
    private readonly _router: Router,
    private readonly _auth: ZeissIdService,
    private readonly _api: ApiService
  ) {}

  public startWorkflow(): void {
    if(!this._auth.internal.oAuthService.hasValidIdToken()) {
      this._auth.signIn();
    }

    this._api.initializeMeasurementFactory();
    this._router.navigate(['/ergonomics']);
  }
}
