import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { isNullOrEmtpy, parseLocalFloat } from '@utils/validation.utils';


@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css']
})
export class FormFieldComponent {

  @Input({required: true}) control!: FormControl;

  @Input() unit: string = '';  

  @Input() full: boolean = false;

  @Input() autoformat: boolean = true;

  @Output() change: EventEmitter<number> = new EventEmitter<number>();

  public onBlur(value: any): void {
    if(!this.autoformat) return;
    if(isNullOrEmtpy(value)) return;

    // format number locailized with 2 decimal places
    const parsed = parseLocalFloat(value).toLocaleString(navigator.language, {minimumFractionDigits: 2, maximumFractionDigits: 2});

    if(isNaN(parseLocalFloat(parsed))) return;

    this.control.setValue(parsed);
  }
}
