import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "@components/home/home.component";
import {ErgonomicsComponent} from "@components/ergonomics/ergonomics.component";
import {SummaryComponent} from "@components/summary/summary.component";
import {CentrationComponent} from "@components/centration/centration.component";
import {RefractionComponent} from "@components/refraction/refraction.component";
import { AuthenticationProxyGuard } from './guards/auth-proxy.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: "full",
  },
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: "full"
  },
  {
    path: 'ergonomics',
    component: ErgonomicsComponent,
    pathMatch: "full",
    canActivate: [AuthenticationProxyGuard]
  },
  {
    path: 'summary',
    component: SummaryComponent,
    pathMatch: "full",
    canActivate: [AuthenticationProxyGuard]
  },
  {
    path: 'centration',
    component: CentrationComponent,
    pathMatch: "full",
    canActivate: [AuthenticationProxyGuard]
  },
  {
    path: 'refraction',
    component: RefractionComponent,
    pathMatch: "full",
    canActivate: [AuthenticationProxyGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
