/**
 * DEVELOPMENT Environment
 * This file can be replaced during build by using the `fileReplacements` array.
 * `ng build --configuration production` replaces `environment.ts` with `environment.prod.ts`.
 */
export const environment = {
    production: false,
    apiBaseUrl: 'https://esb-dev.zeiss.com/public/api/med/eyemagconsult',
    apiSubscriptionKey: 'dc6c9e6234534995a28521d2ebb59a7c',
    apiSubscriptionKeyHeader: 'EsbApi-Subscription-Key',
    zeissIdConfig: {
        discoveryDocumentUrl: 'https://id-ip-stage.zeiss.com/OAuth/OpenidConfiguration',
        issuer: 'https://b2cstage.zeiss.com/483f449b-6a0d-4354-b7e7-37113e7d4ca1/v2.0/',
        clientId: 'c71f747f-4108-4caa-aeed-aa68cc03640a',
        clientSecret: '8oO8Q~4nW~-rLkMJ1xMyGeTtVjPhDsClteBGub_F',
        debug: true,
    },
    legal: {
        privacyNotice: 'https://www.zeiss.com/data-protection/en/home.html',
        legalNotice: 'https://www.zeiss.com/meditec-ag/legal-information/data-protection-statement-and-legal-notice.html',
        publisher: 'https://www.zeiss.com/meditec-ag/legal-information/company-information.html',
        contact: 'https://www.zeiss.com/meditec-ag/about-us/contact-us.html'
    }
};
