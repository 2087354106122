import { minLogLevel } from './debug.utils';
import { LogLevel } from '@typings/log-level.enum';

export class Logger {
  private static isColorSupported = true;

  private static getColorCode(level: LogLevel): string {
    switch (level) {
      case LogLevel.Debug:
        return '\x1b[35m'; // Magenta
      case LogLevel.Info:
        return '\x1b[32m'; // Green
      case LogLevel.Error:
        return '\x1b[31m'; // Red
      case LogLevel.Fatal:
        return '\x1b[41m\x1b[37m'; // White text on red background
      case LogLevel.Warning:
        return '\x1b[33m'; // Yellow
      default:
        return '';
    }
  }

  private static resetColor(): string {
    return '\x1b[0m';
  }

  private static shouldLog(level: LogLevel): boolean {
    if (!minLogLevel) return true; // If no setting found, log all levels
    const keys = Object.keys(LogLevel).map(k => k.toUpperCase());
    return keys.indexOf(minLogLevel.toUpperCase()) <= keys.indexOf(level.toUpperCase());
  }

  private static log(level: LogLevel, message: string, ...params: any[]): void {
    if (!this.shouldLog(level)) {
      return;
    }

    const colorCode = this.getColorCode(level);
    const resetCode = this.resetColor();
    const formattedMessage = `${colorCode}[${level}] ${message}${resetCode}`;
    console.log(formattedMessage, ...params);
  }

  static formatted(level: LogLevel, message: string, ...params: any[]): void {
    if (this.isColorSupported) {
      this.log(level, message, ...params);
    } else {
      console.log(`[${level}] ${message}`, ...params);
    }
  }

  static debug(message: string, ...params: any[]): void {
    if (this.isColorSupported) {
      this.log(LogLevel.Debug, message, ...params);
    } else {
      console.debug(`[${LogLevel.Debug}] ${message}`, ...params);
    }
  }

  static info(message: string, ...params: any[]): void {
    if (this.isColorSupported) {
      this.log(LogLevel.Info, message, ...params);
    } else {
      console.info(`[${LogLevel.Info}] ${message}`, ...params);
    }
  }

  static warning(message: string, ...params: any[]): void {
    if (this.isColorSupported) {
      this.log(LogLevel.Warning, message, ...params);
    } else {
      console.warn(`[${LogLevel.Warning}] ${message}`, ...params);
    }
  }

  static error(message: string, ...params: any[]): void {
    if (this.isColorSupported) {
      this.log(LogLevel.Error, message, ...params);
    } else {
      console.error(`[${LogLevel.Error}] ${message}`, ...params);
    }
  }

  static fatal(message: string, ...params: any[]): void {
    if (this.isColorSupported) {
      this.log(LogLevel.Fatal, message, ...params);
    } else {
      console.error(`[${LogLevel.Fatal}] ${message}`, ...params);
    }
  }
}
