<ng-container>
  <div class="iosfix">
    <app-navbar 
      [username]="username" 
      [offline]="(offline$ | async)!"
      [cacheState]="(cacheState$ | async)!"
      [cache]="applicationCache"
      (logout)="logout()"
      (languageChange)="changeLanguage()"
      (synchronize)="synchronize()"
    >
    </app-navbar>

    <div *ngIf="showDialog && (updateAvailable$ | async)!" class="fixed top-0 bottom-0 left-0 right-0 z-50 backdrop-brightness-50">
      <zui-dialogbox
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-80"
        [confirmPosition]="'right'"
        [headerText]="'components.updateDialog.title' | translate"
        (zui-dialog-box-accepted)="update()"
        (zui-dialog-box-cancelled)="closeDialog($any($event))"
      >
        <p class="pt-4 space-y-2">
          {{ 'components.updateDialog.description' | translate }}
        </p>
      </zui-dialogbox>
    </div>

    <div class="relative w-full" style="height: calc(100% - 64px)">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>