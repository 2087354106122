
/**
 * Generates a correction factor for a given value.
 * The correction factor is used to add precision to the number.
 * The function is shifting the decimal point to the right by the number of digits after the decimal point.
 * 
 * @example
 * // returns 100
 * generateCorrectionFactor(1.00)
 * @example
 * // returns 1000
 * generateCorrectionFactor(1.000)
 * @param value value to calculate the correction factor from
 * @returns correction factor
 */
export const generateCorrectionFactor = (value: number): number => {
  let v = value.toString();
  const parts = v.split('.');

  if (parts.length !== 2) {
    v = parseFloat(value.toString()).toFixed(2);
  }

  return Math.pow(10, v.split('.')[1].length >= 2 ? v.split('.')[1].length : 2);
};

/**
 * Checks if the given value is a valid number.
 * Valid numbers can have a sign (+/-) and decimal places (.,)
 * @example
 * // returns true
 * isNumber('1.00')
 * @example
 * // returns true
 * isNumber('1,00')
 * @example
 * // returns true
 * isNumber('-1.00')
 * @example
 * // returns true
 * isNumber('-1,00')
 * @example
 * // returns false
 * isNumber('1,00.00')
 * @example
 * // returns false
 * isNumber('1,00,00')
 * @example
 * // returns false
 * isNumber('1.00.00')
 * @example
 * // returns false
 * isNumber('1.00,00')
 * 
 * @param value value to check
 * @returns true if the value is a valid number
 */
export const isNumber = (value: string): boolean => {{
    const pattern = new RegExp(/^[+-]?\d+([\.,]\d*)?$/);
    return pattern.test(value);
}}

/**
 * checks if the given value is null, undefined or empty
 * @param value value to check
 * @returns true if the value is null, undefined or empty
 */
export const isNullOrEmtpy = (value: string | null | undefined): boolean => {
  return value === null || value === undefined || value === '';
}

export const parseLocalFloat = (value: string): number => {
  return parseFloat(value.replace(',', '.'));
}
